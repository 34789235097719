import React from 'react';
import { connect } from 'react-redux';
import { FaRegUserCircle } from 'react-icons/fa';

import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import { LoginButton, RegisterButton } from '../Header/welcome';

const WrapperHeader = ({ config, user }) => {
    const lotteryUrl = config?.config?.lottery_host ?? 'https://www.galottery.com';

    return (
        <div className='lottery-wrapper'>
            <div className='header'>
                <div className='global-nav'>
                    <div className='container-fluid'>
                        <div className='account-navigation'>
                            <div className='account-nav' id='accountBarDesktop'>
                                {user.loggedIn ? (
                                    <></>
                                ) : (
                                    <div className='account-nav__not-auth'>
                                        <div className='header-playersclub-login'>
                                            <div className='header-playersclub-logo sprite-playersclublogo'>
                                                <span className='sr-only'>Players Club</span>
                                            </div>
                                            <div className='row'>
                                                <div className='col-xs-6'>
                                                    <span className='label'>What is Players Club?</span>
                                                </div>
                                                <div className='col-xs-6'>
                                                    <span className='label'>Don&apos;t have an account yet?</span>
                                                </div>
                                            </div>
                                            <div className='row account-nav__not-auth__action-wrapper'>
                                                <div className='col-xs-5'>
                                                    <div className='dropdown'>
                                                        <LoginButton>
                                                            <a
                                                                title='GA Lottery Player Login'
                                                                href='#'
                                                                id='loginPopup'
                                                                data-toggle='dropdown'
                                                                aria-haspopup='true'
                                                                aria-expanded='false'
                                                                className='btn btn-login btn-block'>
                                                                Sign In
                                                            </a>
                                                        </LoginButton>
                                                    </div>
                                                </div>
                                                <div className='col-xs-5'>
                                                    <RegisterButton>
                                                        <a
                                                            title='Join GA Lottery'
                                                            href={`${lotteryUrl}/en-us/registration.html`}
                                                            className='btn btn-register btn-block'>
                                                            register
                                                        </a>
                                                    </RegisterButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/*
                    //! Breadcrumbs - not needed at the moment
                    <div className="breadcrumb-container">
                        <div className="container-fluid">
                            <ol className="breadcrumb">
                                <li><a title="Home" href={`${lotteryUrl}/en-us/home.html`}>Home</a></li>
                                <li><a href={`${lotteryUrl}/en-us/games.html`}>Games</a></li>
                                <li className="active">Second Chance</li>
                            </ol>
                        </div>
                    </div>
                    */}
                </div>

                <nav className='navbar navbar-pd-mobile' role='navigation'>
                    <div className='navbar-header'>
                        <a href={`${lotteryUrl}/en-us/home.html`}>
                            <button type='button' data-toggle='mainNav' className='navbar-toggle'>
                                <span className='sr-only'>Toggle navigation</span>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                            </button>
                        </a>

                        <a className='nav-logo-sm' href={`${lotteryUrl}/en-us/home.html`}>
                            <span className='sr-only'>Georgia - Lottery</span>
                            <img src='/wrapper/content/dam/portal/images/ga-lottery.png' alt='' />
                        </a>

                        {user.loggedIn ? (
                            <a href={`${lotteryUrl}/en-us/myaccount.html`}>
                                <button
                                    data-pd='authenticated'
                                    type='button'
                                    data-toggle='accountNav'
                                    className='navbar-toggle-details navbar-toggle-details--logged-in'
                                    style={{ display: 'inline-block' }}>
                                    <span className='sr-only'>Personal Details</span>
                                    <span>
                                        <FaRegUserCircle className='pd-icon pd-icon-personal-details' />
                                    </span>
                                    <span className='navbar-toggle-details--nameplate' data-pd='playerName'>
                                        {user?.player?.first_name}
                                    </span>
                                </button>
                            </a>
                        ) : (
                            <LoginButton>
                                <button
                                    data-pd='not-authenticated'
                                    data-toggle='accountNav'
                                    className='btn btn-login btn-sm navbar-toggle-details navbar-toggle-details--logged-out'
                                    id='mobileLoginBtn'>
                                    Sign in
                                </button>
                            </LoginButton>
                        )}
                    </div>
                </nav>

                <div className='mainmenu parbase'>
                    <nav className='navbar navbar-pd' role='navigation'>
                        <LoginButton>
                            <button type='button' className='navbar-toggle navbar-toggle-my-account' data-toggle='accountNav'>
                                Sign in
                            </button>
                        </LoginButton>
                        <div id='pd-main-nav' aria-hidden='false'>
                            <div className='container-fluid'>
                                <a className='nav-logo' href={`${lotteryUrl}/en-us/home.html`}>
                                    <span className='sprite-ga-lottery-bdr'>
                                        <span className='sr-only'>Georgia - Lottery</span>
                                    </span>
                                </a>
                                <ul className='nav navbar-nav pd-navbar-nav' role='navigation' data-role='topNav'>
                                    <li className='dropdown' data-section='games'>
                                        <a
                                            href={`${lotteryUrl}/en-us/games.html`}
                                            className='dropdown-toggle'
                                            data-toggle='dropdown'
                                            role='button'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                            title='Games'>
                                            Games
                                        </a>
                                    </li>
                                    <li className='dropdown' data-section='buy-now'>
                                        <a
                                            href={`${lotteryUrl}/en-us/buy-now.html`}
                                            className='dropdown-toggle'
                                            data-toggle='dropdown'
                                            role='button'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                            title='Buy Now'>
                                            Buy Now
                                        </a>
                                    </li>
                                    <li className='dropdown' data-section='winners'>
                                        <a
                                            href={`${lotteryUrl}/en-us/winners/winners-gallery.html`}
                                            className='dropdown-toggle'
                                            data-toggle='dropdown'
                                            role='button'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                            title='Winners'>
                                            Winners
                                        </a>
                                    </li>
                                    <li className='dropdown' data-section='player-zone'>
                                        <a
                                            href={`${lotteryUrl}/en-us/player-zone.html`}
                                            className='dropdown-toggle'
                                            data-toggle='dropdown'
                                            role='button'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                            title='Player Zone'>
                                            Player Zone
                                        </a>
                                    </li>
                                    <li className='dropdown' data-section='benefitting-georgia'>
                                        <a
                                            href={`${lotteryUrl}/en-us/benefitting-georgia.html`}
                                            className='dropdown-toggle'
                                            data-toggle='dropdown'
                                            role='button'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                            title='Benefitting Georgia'>
                                            Benefitting Georgia
                                        </a>
                                    </li>
                                    <li data-section='winning-numbers'>
                                        <a href={`${lotteryUrl}/en-us/winning-numbers.html`} title='Winning Numbers'>
                                            Winning Numbers
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <div role='main'>
                <div className='page-hero'>
                    <div className='container-fluid'>
                        <h1>Second Chance</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WrapperHeader);
