import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { BoxedContent } from 'sg-ui-components';

import promotionConfig from '../promotionConfig';

const Logout = ({ user, config, cmsSourceFirebase, actions }) => {
    const page = {
        title: 'Logout',
        className: 'logout',
    };

    useEffect(() => {
        if (config.config && user.loggedIn) {
            handleLogout();
        }
    }, [config, user]);

    const handleLogout = async () => {
        const myHost = window.location.protocol + '//' + window.location.host;
        const logoutDestination = promotionConfig.getLogoutDestination(myHost, config.config.logout_host_uri);

        window.location.search = '';
        await actions.userActions.logout();
        window.location.href = logoutDestination;
    };

    return (
        <Layout {...{ page, user, config, cmsSourceFirebase, actions }}>
            <BoxedContent note='Logout section' hash='logout' hasCollapser={false} title={page?.title}>
                <div className='my-2 text-center'>You have successfully been logged out.</div>
            </BoxedContent>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
