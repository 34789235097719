import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { mapDispatchToProps, mapStateToProps } from '../Store';

const TokenExpired = ({ scratchTicket, igt24Ticket }) => {
    const [triggerLogout, setTriggerLogout] = useState(false);

    useEffect(() => {
        // Scratch ticket related validation
        if (scratchTicket.message === '_TOKEN_EXPIRED') {
            setTriggerLogout(true);
        }

        // IGR mobiele app scan related validation
        if (igt24Ticket.message === '_TOKEN_EXPIRED') {
            setTriggerLogout(true);
        }
    }, [scratchTicket.message]);

    return triggerLogout ? <Redirect to='/logout' /> : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenExpired);
