var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TeleScript, getCMSObject } from '../../../index';
export var GamePlay = function (_a) {
    var _b;
    var _c = _a.gameServer, gameServer = _c === void 0 ? '' : _c, _d = _a.gamesData, gamesData = _d === void 0 ? [] : _d, _e = _a.token, token = _e === void 0 ? '' : _e;
    var _f = useState(true), show = _f[0], setShow = _f[1];
    var _g = useState(''), gameUrl = _g[0], setGameUrl = _g[1];
    var _h = useState(gamesData), pendingGames = _h[0], setPendingGames = _h[1];
    var postClaimMessages = (_b = getCMSObject('data.components.teleScripts.postClaimMessaging.jsonBlock')) !== null && _b !== void 0 ? _b : '';
    // ***********************************************************************************
    // Takes the first game in the gamesData array and creates the game URL.
    // updates whenever the gamesData array changes so that it can always play the next
    // game in the list.
    //
    useEffect(function () {
        var _a;
        if (pendingGames.length > 0) {
            var currentGameData = pendingGames[0];
            var gameId = (_a = currentGameData.gameId) !== null && _a !== void 0 ? _a : '';
            if (gameId !== '' && (token !== null && token !== void 0 ? token : '') !== '') {
                var gameOptions = {
                    gameId: currentGameData.gameId,
                    serverType: 'Static',
                    outcomeData: currentGameData.outcomeData,
                    sageOptions: {
                        promotionId: 1,
                        leaderBoardId: 1,
                    },
                    additionalData: currentGameData.additionalData,
                    useMinimized: false,
                };
                var gameData = 'options=' + encodeURIComponent(JSON.stringify(gameOptions));
                var revealUrl = "".concat(gameServer, "/api/games/url?token=").concat(token !== null && token !== void 0 ? token : '');
                var xhr_1 = new XMLHttpRequest();
                xhr_1.addEventListener('readystatechange', function () {
                    if (xhr_1.readyState === 4) {
                        try {
                            var responseObj = JSON.parse(xhr_1.responseText);
                            setGameUrl(responseObj.gameUrl);
                            window.addEventListener('message', gameEventListener);
                        }
                        catch (err) {
                            console.error('Could not retrieve game URL - ', err);
                        }
                    }
                });
                xhr_1.open('POST', revealUrl);
                xhr_1.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr_1.send(gameData);
                setShow(true);
            }
        }
        return function () {
            setGameUrl(null);
            setShow(false);
        };
    }, [pendingGames]);
    //* **********************************************************************************
    // Listen for the enterTicket event which is our indication the the game has
    // finished playing.
    //
    var gameEventListener = function (event) {
        var _a;
        if (event.data === undefined) {
            return;
        }
        if (((_a = event.data) === null || _a === void 0 ? void 0 : _a.type) === 'enterTicket') {
            handleClose();
        }
    }; // end gameEventListener
    // ***********************************************************************************
    // Close the modal and remove the first game in gamesData array since it was
    // just played.
    //
    var handleClose = function () {
        setShow(false);
        var tempGameData = pendingGames;
        tempGameData.shift();
        setPendingGames(__spreadArray([], tempGameData, true));
    };
    // ***********************************************************************************
    // Allows the user to skip all games by setting the gamesData array to an empty
    // array
    //
    var skipGames = function () {
        handleClose();
        setPendingGames([]);
    };
    // ***********************************************************************************
    // Scroll the user to the top of the page
    //
    var scrollToTop = function () {
        var _a;
        var element = document.getElementById('reveal-game-container');
        (_a = element === null || element === void 0 ? void 0 : element.scrollIntoView) === null || _a === void 0 ? void 0 : _a.call(element, true);
    };
    var stringifiedGameUrl = gameUrl !== null && gameUrl !== void 0 ? gameUrl : '';
    if (show && stringifiedGameUrl !== '' && stringifiedGameUrl.length > 0) {
        return (_jsx("div", { children: _jsxs(Modal, { show: show, onHide: handleClose, backdrop: 'static', keyboard: false, size: 'lg', id: 'reveal-game-container', dialogClassName: 'reveal-game-dialog', contentClassName: 'reveal-game-modal', backdropClassName: 'reveal-game-modal-backdrop', children: [_jsx(Modal.Header, { closeButton: true, children: _jsx(Modal.Title, { className: 'text-center', children: _jsx(TeleScript, { line: postClaimMessages === null || postClaimMessages === void 0 ? void 0 : postClaimMessages.playGameText, children: "Play a Game to Reveal Your Reward" }) }) }), _jsx(Modal.Body, { children: _jsx("iframe", { "data-testid": 'gameplay-iframe', onLoad: scrollToTop, title: 'interactive-reveal', className: 'interactive-reveal-iframe', src: gameUrl !== null && gameUrl !== void 0 ? gameUrl : '', frameBorder: '0' }) }), _jsxs(Modal.Footer, { children: [_jsx("p", { className: 'footer-description', children: _jsx(TeleScript, { line: postClaimMessages === null || postClaimMessages === void 0 ? void 0 : postClaimMessages.skipGameText, children: "If you choose to skip the game, you can still view your reward on your my activity page." }) }), _jsx(Button, { variant: 'outline-success', className: 'btn theme-btn theme-outline skip-button', onClick: skipGames, children: _jsx(TeleScript, { line: postClaimMessages === null || postClaimMessages === void 0 ? void 0 : postClaimMessages.skipGameButtonText, children: "Skip the Game" }) })] })] }) }));
    }
    else {
        return null;
    }
};
export default GamePlay;
