import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getCookieByName, isApp, isBrowser } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import siteConfig from '../promotionConfig';

/********************************************************************************************************
 * Component: UserLockout
 * Purpose:   Handled redirection policy for various actions on the
 *            users profile.   Each action should force the user
 *            to a specific page to complete the action required
 *
 * Props: -   children -  reference to the page the user wanted to navigate to
 *            loading  -  reference to the loading store
 *            user     -  reference to the user store
 *            config   -  reference to the config store
 *            currentSection -  Identifies the current section of the page we are on (optional)
 *            disableRedirect -  Tells the component to NOT redirect to the action page - for
 *                              instance if we are already on that action to prevent redirect loop.  (optional)
 *
 * APIs used:  none
 */
const UserLockout = ({ children, loading, user, config, currentSection, disableRedirect }) => {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, [loading, hasMounted, user]);

    let myLocation = isBrowser() ? window.location.protocol + '//' + window.location.host + '/login-pta' : '';

    // remove any trailing slashes
    myLocation = myLocation.replace(/\/$/, '');

    const defaultLoginUrl = siteConfig.defaultLoginLink;

    //? for debug: console.log('🔥 Loading actions', loading.actions, hasMounted);

    //! TODO: need to DEBUG sometimes getPlayerData never returns
    if (!hasMounted || loading.actions['getPlayerData'] || loading.actions['getConfig'] || loading.actions['login']) {
        return (
            <div className='loading-wrapper'>
                {/*
                    //! Removed because the styling issues
                    children
                */}
                <div className='loading-overlay'>
                    <div className='loading-icon ellipses'></div>
                </div>
            </div>
        );
    } else {
        if (user.loggedIn) {
            if (user.player?.actions?.length > 0) {
                if (user.player.actions.includes('update-account')) {
                    return <Redirect to='/register?step=2' />;
                } else if (user.player.actions.includes('verify-account')) {
                    return <Redirect to='/register?step=4' />;
                } else if (user.player.actions.includes('manual-verify')) {
                    return <Redirect to='/register-verification-error' />;
                } else if (user.player.actions.includes('account-confirm')) {
                    return <Redirect to='/await-account-confirmation' />;
                } else if (
                    Object.prototype.hasOwnProperty.call(user.player.actions[0], 'take-survey') &&
                    disableRedirect !== 'survey' &&
                    currentSection !== 'survey' &&
                    currentSection !== 'post-claim'
                ) {
                    const thisAction = user.player.actions[0];
                    const survey = thisAction['take-survey'];
                    return <Redirect to={'/survey?survey=' + survey} />;
                } else if (
                    user.player.actions.includes('review-optins') &&
                    disableRedirect !== 'survey' &&
                    currentSection !== 'survey' &&
                    currentSection !== 'post-claim' &&
                    currentSection !== 'my-profile' &&
                    disableRedirect !== 'change-password'
                ) {
                    return <Redirect to='/my-profile#my-notifications' />;
                } else if (
                    user.player.actions.includes('change-password') &&
                    disableRedirect !== 'change-password' &&
                    currentSection !== 'my-profile' &&
                    currentSection !== 'password-expired'
                ) {
                    return <Redirect to='/password-expired' />;
                }
                return children;
            } else {
                return children;
            }
        } else {
            // It's possible that the user could be logged in but the store isn't up to date
            // yet -  so check the AuthToken cookie, if its there, then the user should be soon
            // logged in - if not go to login screen.

            if (!getCookieByName('AuthToken')) {
                if (isApp()) {
                    let myLocation = typeof window !== 'undefined' ? window.location.protocol + '//' + window.location.host + window.location.pathname : '';
                    // remove any trailing slashes
                    myLocation = myLocation.replace(/\/$/, '');

                    console.log('🚀 triggerAppLogin from: ' + myLocation);
                    window.parent.postMessage(
                        {
                            message: 'openLogin',
                            redirect: myLocation,
                        },
                        '*'
                    );
                } else {
                    const loginUrl = config.config?.login_host_uri ? `${config.config.login_host_uri}?destURL=${myLocation}` : defaultLoginUrl;
                    window.location.href = loginUrl;
                }
            }

            return null;
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLockout);
