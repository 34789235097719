import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import TokenExpired from '../Components/TokenExpired';

import PostClaim from '../Components/Postclaim/PostClaim';

const PostClaimPage = ({ user, config, cmsSourceFirebase, actions }) => {
    const page = {
        title: 'Enter Tickets',
        className: 'post-claim',
    };

    return (
        <Layout {...{ page, user, config, cmsSourceFirebase, actions }}>
            <TokenExpired />
            <PostClaim />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostClaimPage);
