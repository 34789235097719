import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { HomeEnterTickets, NextDrawings, CurrentSweepstakes, getCMSObject, formatDateTBD } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';

import Layout from '../Components/Layout';
import EnterTicketTabs from '../Components/Enter/EnterTicketTabs';
import UserLockout from '../utils/user_lockout';

import promotionConfig from '../promotionConfig';

const Home = ({ user, config, sweep, winner, cmsSourceFirebase, actions }) => {
    const page = {
        title: 'Home',
        className: 'home',
    };

    const urlParams = new URLSearchParams(window.location.search);

    const telescriptHomeEnterTickets = getCMSObject('data.components.teleScripts.homeEnterTickets.jsonBlock');
    const telescriptNextDrawing = getCMSObject('data.components.teleScripts.nextDrawing.jsonBlock');
    const telescriptHomeSweepstakes = getCMSObject('data.components.teleScripts.homeSweepstakes.jsonBlock');

    useEffect(() => {
        if (!user.loggedIn && urlParams.get('Hash')) {
            const ptaURL = window.location.protocol + '//' + window.location.host + '/login-pta' + window.location.search;
            window.location.href = ptaURL;
        }
    }, [user?.loggedIn]);

    return (
        <Layout {...{ page, user, config, cmsSourceFirebase, actions }}>
            <div className='row justify-content-center'>
                <div className='col-lg-6 entry-box' id='home-enter-ticket-container'>
                    <HomeEnterTickets user={user} telescript={telescriptHomeEnterTickets ?? ''}>
                        <UserLockout>
                            <EnterTicketTabs />
                        </UserLockout>
                    </HomeEnterTickets>
                </div>
                <div className='col-lg-6' id='home-next-drawing-container'>
                    <NextDrawings
                        {...{ user, winner, cmsSourceFirebase, actions }}
                        formatDate={formatDateTBD?.dateWithDayOfTheWeek}
                        telescript={telescriptNextDrawing ?? ''}
                        options={{
                            variant: 'theme-primary',
                            limitOnePerSweepstake: promotionConfig?.nextDrawingsLimitOnePerSweepstake ?? true,
                            maxElement: promotionConfig?.nextDrawingsMaxElement ?? 5,
                        }}
                    />
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <CurrentSweepstakes
                        {...{ sweep, cmsSourceFirebase, actions }}
                        telescript={telescriptHomeSweepstakes}
                        mode='top3_with_show_more'
                        promotionLink='/promotions'
                        options={{ variant: 'theme-primary' }}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
