import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import EnterTicketsPage from '../Components/Enter/EnterTicketsPage';

const EnterPage = ({ user, config, cmsSourceFirebase, actions }) => {
    const page = {
        title: 'Enter Tickets',
        className: 'enter',
    };

    return (
        <Layout {...{ page, user, config, cmsSourceFirebase, actions }}>
            <EnterTicketsPage />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterPage);
