module.exports = {
    routes: {
        promotions: {
            title: 'Promotions',
            pageHeader: 'Promotions',
            className: 'promotions',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions' },
            ],
        },
        secondchancepromotion: {
            title: 'Promotion Details',
            pageHeader: 'Promotion Details',
            className: 'second-chance-promotion',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions#promotions' },
                { name: 'Promotion Details', path: '' },
            ],
        },
        winners: {
            title: 'Drawings & Winners',
            pageHeader: 'Drawings & Winners',
            className: 'winners',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Drawings & Winners', path: '/winners' },
            ],
        },
        myactivity: {
            title: 'My Activity',
            pageHeader: 'My Activity',

            className: 'my-activity',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'My Activity', path: '/my-activity' },
            ],
        },
    },
    title: 'GA Ensemble',
    promotion_id: '',
    jurisdiction: 'GA',
    imgFolderName: 'ga',
    promotion: 'ensemble_site',
    appUrl: '',
    lottoUrl: '',
    prodhost: '',
    uathost: '',
    gaTag: 'GTM-NNXRLL9',
    feedbackIssueZone: 'SCBZ',
    defaultLoginLink: `/login`,
    defaultRegisterLink: '/register',
    getLoginLink: (app, redirect) => (app && redirect ? `` : this.defaultLoginLink),
    getRegisterLink: (app, redirect) => (app && redirect ? `` : this.defaultRegisterLink),
    getLogoutDestination: (hostURL, ptaLogout) => (ptaLogout !== undefined ? ptaLogout : hostURL + '/home'),
    forgot: '/users/forgot',
    testing: {},
    modules: {
        hasWallet: false,
    },
    components: {
        nextDrawings: {
            limitOnePerSweepstake: true,
            maxElement: 5,
        },
        recentDrawings: {
            defaultPageSize: 10,
        },
        drawingEntries: {
            defaultPageSize: 5,
        },
        ticketHistory: {
            defaultPageSize: 5,
        },
    },
    resolveCurrency: (name) => {
        const currencyExchange = [
            {
                name: 'sweepstake',
                displayName: 'sweepstake',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
            {
                name: 'lotteryproducts',
                displayName: 'lotteryproducts',
                showQuantity: false,
                isPence: false,
                resolve: (amount) => {
                    return '';
                },
            },
            {
                name: 'promocash',
                displayName: 'promocash',
                showQuantity: true,
                isPence: true,
                resolve: (amount) => {
                    const amt = amount / 100;
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(amt);
                },
            },
            {
                name: 'default',
                displayName: 'default',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
        ];

        return currencyExchange.filter((item) => {
            return item.name === name || item.name === 'default';
        })[0];
    },
    drawTicketLength: '25',
    badTicketInputReplacement: /[o,O]/g, // Regex to remove bad input from ticket fields.
    /*
    Entry Number Format: GGGG PPPPPPP TTT VVVVVVVVVVV NNN
    Entry Input: 4 digit game number + 7 digit pack number + 3 digit ticket number + 11 digit front validation number + 3 digit PIN
    */
    scratchTicketLengthRegex: '^[0-9]{28}$',
    scratchTicketFields: {
        gameNumberFields: [
            {
                name: 'gameNum',
                placeholder: 'GGGG',
                size: '4',
                value: '',
                dataindex: '0',
                side: 'back',
            },
        ],
        fieldGroups: [
            {
                validRanges: [['0000', '9999']],
                frontNumberFields: [
                    {
                        name: 'frontNum',
                        placeholder: 'VVVVVVVVVVV',
                        size: '11',
                        value: '',
                        dataindex: '3',
                        side: 'front',
                    },
                    {
                        name: 'pinNum',
                        placeholder: 'NNN',
                        size: '3',
                        value: '',
                        dataindex: '4',
                        side: 'front',
                    },
                ],
                backNumberFields: [
                    {
                        name: 'packNum',
                        placeholder: 'PPPPPPP',
                        size: '7',
                        value: '',
                        dataindex: '1',
                        side: 'back',
                    },
                    {
                        name: 'ticketNum',
                        placeholder: 'TTT',
                        size: '3',
                        value: '',
                        dataindex: '2',
                        side: 'back',
                    },
                ],
            },
        ],
    },
    nextDrawingsLimitOnePerSweepstake: true,
    nextDrawingsMaxElement: 5,
    epochEndDate: 2147385600, // Jan 18, 2038 Midnight
    banners: {
        homeCarousel: 'glb_home_page_carousel',
    },
    apiClientConfig: {
        appUrl: '',
        routes: {
            getConfig: 'ctlEnsemble.getConfig',
            login: 'loyalty2.ptaLogin',
            //? for testing: login: 'ctlEnsemble.login',
            logout: 'ctlEnsemble.logout',
            checkTicket: 'mrbEnsemble.checkTicket',
            enterIGT24Ticket: 'ctlEnsemble.enterTicket',
            enterDrawTicket: 'mrbEnsemble.enterTicket',
            enterScratchTicket: 'mrbEnsemble.enterTicket',
            enterFastPlayTicket: 'mrbEnsemble.enterTicket',
            getEntries: 'scbzApi.getEntries',
            getPlayerData: 'loyalty2.getPlayerDataRam',
            setEntryFlashGameFlag: 'scbzApi.setEntryFlashGameFlag',
            update: 'ctlEnsemble.update',
            register: 'mrbEnsemble.register',
            verify: 'ctlEnsemble.verify',
            confirm: 'ctlEnsemble.confirm',
            confirmResend: 'ctlEnsemble.confirmResend',
            winners: 'ctlEnsemble.winners',
            postFeedback: 'ctlEnsemble.postFeedback',
            getFeedbackCategories: 'ctlEnsemble.getFeedbackCategories',
            forgotPassword: 'ctlEnsemble.forgotPassword',
            recoverPassword: 'ctlEnsemble.recoverPassword',
            getGame: 'fauxScbzApi.getGame',
            getGameOver: 'fauxScbzApi.getGameOver',
            getAchievements: 'ctlEnsemble.getAchievements',
            getPoints: 'ctlEnsemble.getPoints',
            getActivity: 'ctlEnsemble.getActivity',
            getReward: 'ctlEnsemble.getReward',
            getCoupons: 'mrbEnsemble.getCoupons',
            getPromotions: 'ctlEnsemble.getPromotions',
            getPlayerPromotions: 'ctlEnsemble.getPlayerPromotions',
            getSweeps: 'ctlEnsemble.getSweeps',
            getSweepDetails: 'mrbEnsemble.getSweepDetails',
            allocateEntries: 'mrbEnsemble.allocateEntries',
            getActiveSweeps: 'ctlEnsemble.getActiveSweeps',
            getCurrentSweeps: 'ctlEnsemble.getCurrentSweeps',
            getExpiredSweeps: 'ctlEnsemble.getExpiredSweeps',
            getRevealToken: 'ctlEnsemble.getRevealToken',
            getBanner: 'ctlEnsemble.getBanner',
            expiredDrawings: 'ctlEnsemble.recentDrawings',
            currentDrawings: 'ctlEnsemble.currentDrawings',
            nextDrawings: 'ctlEnsemble.nextDrawings',
            getAllOptins: 'mrbEnsemble.getAllOptins',
            getOptins: 'mrbEnsemble.getOptins',
            optinSubscribe: 'mrbEnsemble.optinSubscribe',
            optinUnsubscribe: 'mrbEnsemble.optinUnsubscribe',
            sendRafInvite: 'mrbEnsemble.sendRafInvite',
            getRafCode: 'mrbEnsemble.getRafCode',
            getActiveSurveys: 'mrbEnsemble.getActiveSurveys',
            getSurveyStatus: 'mrbEnsemble.getSurveyStatus',
            getSurveyQuestions: 'mrbEnsemble.getSurveyQuestions',
            getNamedSurveyQuestions: 'mrbEnsemble.getNamedSurveyQuestions',
            setSurveyAnswer: 'mrbEnsemble.setSurveyAnswer',
            completeSurvey: 'mrbEnsemble.completeSurvey',
            getDashboard: 'mrbEnsemble.getDashboard',
            submitSocialMediaClick: 'mrbEnsemble.submitSocialMediaClick',
            getPlayerAllocatableEntries: 'mrbEnsemble.getPlayerAllocatableEntries',
            getPointsHistory: 'mrbEnsemble.getPointsHistory',
            actionComplete: 'mrbEnsemble.actionComplete',
            callExternalEndpoint: 'mrbEnsemble.callExternalEndpoint',
            getThirdPartyPrizes: 'mrbEnsemble.getThirdPartyPrizes',
            getThirdPartyPrizeBarcode: 'mrbEnsemble.getThirdPartyPrizeBarcode',
            getTicketActivity: 'mrbEnsemble.getTicketActivity',

        },
    },
};
