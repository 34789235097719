var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
import { bonusingSlice } from './BonusingStore';
import isJSON from '../utils/isJSON';
export var defaultState = {
    lastRequested: null,
    surveyQuestions: [],
    code: '',
    errors: {},
    status: null,
    survey_id: null,
    required_questions: null,
    required_questions_answered: null,
    total_questions: null,
    total_questions_answered: null,
};
// REDUCERS
var getNamedSurveyQuestionsReducer = function (state, action) {
    var payload = action.payload;
    if (payload && payload.isError) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), errors: isJSON(payload.message) && JSON.parse(payload.message) });
    }
    else {
        delete payload.error;
        delete payload.isError;
        var surveyQuestions = Object.values(payload);
        return __assign(__assign({}, state), { lastRequested: Date.now(), surveyQuestions: surveyQuestions });
    }
};
var completeSurveyReducer = function (state, action) {
    var payload = action.payload;
    if (payload && payload.isError) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), errors: isJSON(payload.message) && JSON.parse(payload.message) });
    }
    else {
        return __assign(__assign({}, state), { lastRequested: Date.now(), status: 'completed' });
    }
};
var setSurveyAnswerReducer = function (state, action) {
    var payload = action.payload;
    if (payload && payload.isError) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), errors: isJSON(payload.message) && JSON.parse(payload.message) });
    }
    else {
        // Update the state based on the payload answer which is a very small subset of the state's surveyQuestions
        // Find the current question in the surveyQuestions based on the payload question id and then replace
        // the answer with the payload answer.
        var currentQuestions = state.surveyQuestions;
        var questionIndex = currentQuestions.findIndex(function (question) { return question.id === payload.question_id; });
        var changedQuestion = currentQuestions[questionIndex];
        delete changedQuestion.answers;
        var newAnswers = [{ response: payload.answer }];
        changedQuestion.answers = newAnswers;
        currentQuestions[questionIndex] = changedQuestion;
    }
};
var getSurveyStatusReducer = function (state, action) {
    var payload = action.payload;
    return __assign(__assign({}, state), { lastRequested: Date.now(), status: payload.status ? payload.status : '', survey_id: payload.survey_id ? payload.survey_id : '', required_questions: payload.required_questions ? payload.survey_id : '', required_questions_answered: payload.required_questions_answered ? payload.required_questions_answered : '', total_questions: payload.total_questions ? payload.total_questions : '', total_questions_answered: payload.total_questions_answered ? payload.total_questions_answered : '', errors: payload.isError ? isJSON(payload.message) && JSON.parse(payload.message) : null });
};
var resetReducer = function () {
    return __assign({}, defaultState);
};
export var surveySlice = createSlice({
    name: 'surveys',
    initialState: defaultState,
    reducers: {
        getNamedSurveyQuestions: getNamedSurveyQuestionsReducer,
        completeSurvey: completeSurveyReducer,
        setSurveyAnswer: setSurveyAnswerReducer,
        getSurveyStatus: getSurveyStatusReducer,
        resetSurvey: resetReducer,
    },
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
// attempt to complete the survey.   Player must be logged in.
var completeSurvey = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'completeSurvey' }));
                return [4 /*yield*/, apiClient().completeSurvey(payload)];
            case 1:
                result = _a.sent();
                if (result.bonus) {
                    dispatch(bonusingSlice.actions.setBarObject(result.bonus));
                }
                dispatch(surveySlice.actions.completeSurvey(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'completeSurvey' }));
                return [2 /*return*/];
        }
    });
}); };
var getNamedSurveyQuestions = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getNamedSurveyQuestions' }));
                return [4 /*yield*/, apiClient().getNamedSurveyQuestions(payload)];
            case 1:
                result = _a.sent();
                dispatch(surveySlice.actions.getNamedSurveyQuestions(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getNamedSurveyQuestions' }));
                return [2 /*return*/];
        }
    });
}); };
var setSurveyAnswer = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(surveySlice.actions.setSurveyAnswer(payload));
                dispatch(loadingSlice.actions.setLoading({ action: 'setSurveyAnswer' }));
                return [4 /*yield*/, apiClient().setSurveyAnswer(payload)];
            case 1:
                _a.sent();
                dispatch(loadingSlice.actions.unsetLoading({ action: 'setSurveyAnswer' }));
                return [2 /*return*/];
        }
    });
}); };
var getSurveyStatus = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getSurveyStatus' }));
                return [4 /*yield*/, apiClient().getSurveyStatus(payload)];
            case 1:
                result = _a.sent();
                dispatch(surveySlice.actions.getSurveyStatus(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getSurveyStatus' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var surveyActions = function (dispatch, apiClient) {
    return {
        getNamedSurveyQuestions: function (payload) { return getNamedSurveyQuestions(dispatch, payload, apiClient); },
        completeSurvey: function (payload) { return completeSurvey(dispatch, payload, apiClient); },
        setSurveyAnswer: function (payload) { return setSurveyAnswer(dispatch, payload, apiClient); },
        getSurveyStatus: function (payload) { return getSurveyStatus(dispatch, payload, apiClient); },
        reset: function () { return dispatch(surveySlice.actions.resetSurvey()); },
    };
};
