import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, CurrentSweepstakes, stripsTagsAndSlashes } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { routes } from '../promotionConfig';

const Promotions = ({ user, config, sweep, cmsSourceFirebase, actions, match }) => {
    const path = match.path;
    const data = routes[stripsTagsAndSlashes(path)];
    const page = {
        title: data.title,
        className: data.className,
    };

    const telescriptCurrentSweepstakes = getCMSObject('data.components.teleScripts.currentSweepstakes.jsonBlock');

    return (
        <Layout {...{ page, user, config, cmsSourceFirebase, actions, path }}>
            <section id='promotions'>
                <CurrentSweepstakes
                    {...{ sweep, cmsSourceFirebase, actions }}
                    telescript={telescriptCurrentSweepstakes}
                    options={{ hasCollapser: false, variant: 'theme-primary' }}
                />
            </section>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
