import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent, FeedbackForm, getCMSObject } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const Feedback = ({ user, config, cmsSourceFirebase, actions, feedbackCategories, promotion, loading, feedback }) => {
    const page = {
        title: 'Help',
        className: 'feedback',
    };

    const feedbackFormTelescript = getCMSObject('data.components.teleScripts.feedback.jsonBlock');

    return (
        <Layout {...{ page, user, config, cmsSourceFirebase, actions }}>
            {hasCMSContent('data.helpAndFaqs.helpIntro.contentHTML') && (
                <React.Fragment key='Help Intro section'>
                    <div className='row'>
                        <div id='about' className='my-3 col-md-4'>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.helpIntro.image'
                                className='w-100 rounded-image'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </div>
                        <div className='my-3 col d-flex align-items-center playersClubBlurb'>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.helpAndFaqs.helpIntro.contentHTML'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}

            <BoxedContent
                note='How It Works section'
                hash='how-it-works'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.howItWorks.contentHeaderText')}
                title={
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.helpAndFaqs.howItWorks.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                }>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.howItWorks.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>

            <BoxedContent
                note='FAQ section'
                hash='faq'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.faqs.contentHeaderText')}
                title={
                    <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.faqs.contentHeaderText' cmsSourceFirebase={cmsSourceFirebase} />
                }>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.faqs.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>

            <BoxedContent
                note='How To Claim section'
                hash='how-to-claim'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.howToClaim.contentHeaderText')}
                title={
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.helpAndFaqs.howToClaim.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                }>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.howToClaim.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>

            <FeedbackForm
                user={user}
                actions={actions}
                feedbackCategories={feedbackCategories}
                promotion={promotion}
                loading={loading}
                cmsSourceFirebase={cmsSourceFirebase}
                feedback={feedback}
                telescript={feedbackFormTelescript}
            />

            <BoxedContent
                note='Rules section'
                hash='rules'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.rules.contentHeaderText')}
                title={
                    <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.rules.contentHeaderText' cmsSourceFirebase={cmsSourceFirebase} />
                }>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.rules.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>

            <BoxedContent
                note='Terms section'
                hash='terms'
                isActive={false}
                isVisible={hasCMSContent('data.legalAndPolicyItems.termsOfUse.contentHeaderText')}
                title={
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.legalAndPolicyItems.termsOfUse.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                }>
                <CMSContent
                    localStorageObject='webContent'
                    contentPath='data.legalAndPolicyItems.termsOfUse.contentHTML'
                    cmsSourceFirebase={cmsSourceFirebase}
                />
            </BoxedContent>

            <BoxedContent
                note='Privacy section'
                hash='privacy'
                isActive={false}
                isVisible={hasCMSContent('data.legalAndPolicyItems.privacyPolicy.contentHeaderText')}
                title={
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.legalAndPolicyItems.privacyPolicy.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                }>
                <CMSContent
                    localStorageObject='webContent'
                    contentPath='data.legalAndPolicyItems.privacyPolicy.contentHTML'
                    cmsSourceFirebase={cmsSourceFirebase}
                />
            </BoxedContent>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
