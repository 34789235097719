import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get } from 'lodash';
var TableMobile = function (_a) {
    var _b, _c;
    var headers = _a.headers, rows = _a.rows, _d = _a.options, options = _d === void 0 ? {
        tableId: '',
        tableClass: '',
        isLoaded: true,
        messageNoRows: '',
        messageLoading: '',
    } : _d;
    return (_jsx(_Fragment, { children: !rows || rows.length === 0 ? (_jsx("div", { className: 'd-lg-none text-center', children: _jsx("em", { children: options.isLoaded ? options.messageNoRows : (_b = options.messageLoading) !== null && _b !== void 0 ? _b : '. . .' }) })) : (_jsx("div", { className: 'table-responsive', children: _jsx("table", { id: options.tableId, className: (_c = options.tableClass) !== null && _c !== void 0 ? _c : 'table theme-table mobile entry-table d-lg-none text-center', children: _jsx("tbody", { children: Object.keys(headers).map(function (header, id) {
                        var _a;
                        return (_jsxs("tr", { children: [_jsx("td", { className: 'position-sticky', children: (_a = get(headers, header)) !== null && _a !== void 0 ? _a : '' }), rows.map(function (row, rowId) {
                                    var _a;
                                    return _jsx("td", { children: (_a = get(row.fields, header)) !== null && _a !== void 0 ? _a : '-' }, rowId);
                                })] }, id));
                    }) }) }) })) }));
};
export default TableMobile;
