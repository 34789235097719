var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isBrowser, TeleScript, BoxedContent } from '../../../index';
var SweepstakeDetails = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var sweep = _a.sweep, user = _a.user, actions = _a.actions, telescript = _a.telescript, DrawingGroupTable = _a.DrawingGroupTable, _p = _a.ProgressiveJackpot, ProgressiveJackpot = _p === void 0 ? function () { return _jsx(_Fragment, {}); } : _p, _q = _a.setPageTitle, setPageTitle = _q === void 0 ? function () { return _jsx(_Fragment, {}); } : _q;
    var _r = useState({ sweepId: null }), preselection = _r[0], setPreselection = _r[1];
    var sweepstake = null;
    useEffect(function () {
        function getSweepDetailInformation() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, actions.sweepActions.getActiveSweeps()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        getSweepDetailInformation();
        //* Retrieve ID from url params
        var queryString = isBrowser() ? window.location.search : '';
        var urlParams = new URLSearchParams(queryString);
        setPreselection({ sweepId: parseInt(urlParams.get('id')) });
    }, []);
    useEffect(function () {
        if (user.loggedIn) {
            actions.sweepActions.getSweeps();
        }
    }, [user.loggedIn]);
    //* Dynamic Breadcrumbs: change title
    useCallback(function () {
        if (sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.name) {
            setPageTitle(sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.name);
        }
    }, [sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.name]);
    //* Validation check
    var valid = sweep && (sweep === null || sweep === void 0 ? void 0 : sweep.active) && Array.isArray(sweep === null || sweep === void 0 ? void 0 : sweep.active) && (sweep === null || sweep === void 0 ? void 0 : sweep.active.length);
    if (!valid)
        return null;
    sweepstake = sweep.active.filter(function (s) { return s.id === preselection.sweepId; })[0];
    if (!sweepstake)
        return null;
    //* Replace if the user is logged in
    if (user.loggedIn) {
        var sweepstakeWithSummary = sweep === null || sweep === void 0 ? void 0 : sweep.sweeps.filter(function (s) { return s.id === preselection.sweepId; })[0];
        var validUserSweeps = sweep && sweep.sweeps && Array.isArray(sweep.sweeps) && sweep.sweeps.length;
        if (!validUserSweeps)
            return null;
        if (sweepstakeWithSummary) {
            sweepstake = sweepstakeWithSummary;
        }
    }
    //* Dynamic Breadcrumbs: change title
    if (sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.name) {
        setPageTitle(sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.name);
    }
    //* Validation for sweepstake properties for strings and arrays
    var validateProperty = function (prop, limit) {
        if (limit === void 0) { limit = 1; }
        try {
            if (prop && (prop === null || prop === void 0 ? void 0 : prop.length) >= limit) {
                return prop;
            }
        }
        catch (e) {
            return null;
        }
        return null;
    };
    var image = validateProperty((_b = sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.extra) === null || _b === void 0 ? void 0 : _b.image);
    var description = validateProperty((_c = sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.extra) === null || _c === void 0 ? void 0 : _c.description);
    var drawing_groups = (_d = validateProperty(sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.drawing_groups, 0)) !== null && _d !== void 0 ? _d : [];
    var prizes = (_f = validateProperty((_e = sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.drawing_groups[0]) === null || _e === void 0 ? void 0 : _e.prizes, 0)) !== null && _f !== void 0 ? _f : [];
    var rules = validateProperty((_g = sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.extra) === null || _g === void 0 ? void 0 : _g.rules);
    var terms = validateProperty((_h = sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.extra) === null || _h === void 0 ? void 0 : _h.terms);
    var jackpots = (_k = validateProperty((_j = sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.extra) === null || _j === void 0 ? void 0 : _j.jackpot_widget, 0)) !== null && _k !== void 0 ? _k : [];
    jackpots = Array.isArray(jackpots) ? jackpots : (_l = JSON.parse(jackpots)) !== null && _l !== void 0 ? _l : [];
    return (_jsxs("div", { className: 'promotion-details', children: [_jsx("section", { id: 'information', children: _jsx("div", { className: 'mb-3 px-2', children: image ? (_jsxs(Row, { children: [_jsx(Col, { lg: 4, className: 'lottery-promotion-image', children: _jsxs("div", { className: 'progressive-jackpot-container-sweepstake position-relative', children: [(jackpots === null || jackpots === void 0 ? void 0 : jackpots.length) ? _jsx(ProgressiveJackpot, { jackpots: jackpots }) : null, _jsx("img", { src: (_o = (_m = sweepstake.extra) === null || _m === void 0 ? void 0 : _m.image) !== null && _o !== void 0 ? _o : null, alt: sweepstake.name, className: 'rounded-image' })] }) }), _jsxs(Col, { lg: 8, className: 'lottery-promotion-description', children: [_jsx("h1", { className: 'title', children: sweepstake.name }), _jsx("div", { dangerouslySetInnerHTML: { __html: description } })] })] })) : (_jsx(Row, { children: _jsxs(Col, { lg: 12, className: 'my-3', children: [_jsx("h3", { children: sweepstake.name }), _jsx("div", { dangerouslySetInnerHTML: { __html: description } })] }) })) }) }), _jsx(BoxedContent, { note: 'Prizes section', hash: 'prizes', isActive: true, isVisible: prizes === null || prizes === void 0 ? void 0 : prizes.length, title: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.prizesTitle, children: "Prizes" }), children: drawing_groups.map(function (thisDrawingGroup) {
                    return (_jsx(_Fragment, { children: thisDrawingGroup.prizes.map(function (prize_item, index) {
                            var _a, _b, _c, _d;
                            var prize_image = (_b = (_a = prize_item === null || prize_item === void 0 ? void 0 : prize_item.extra) === null || _a === void 0 ? void 0 : _a.image) !== null && _b !== void 0 ? _b : null;
                            var prize_description = (_d = (_c = prize_item === null || prize_item === void 0 ? void 0 : prize_item.extra) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : null;
                            return (_jsxs("div", { className: 'prize-content my-4 mx-md-5', children: [_jsx("h3", { className: 'prizes-tier', children: prize_item === null || prize_item === void 0 ? void 0 : prize_item.tier }), prize_image ? (_jsxs(Row, { children: [_jsx(Col, { lg: 4, className: 'my-3', children: _jsx("div", { className: 'prize-img-container', children: _jsx("img", { src: prize_image, alt: sweepstake.name, className: 'img-fluid prize-img' }) }) }), _jsxs(Col, { lg: 8, className: 'my-lg-3 pt-4', children: [_jsx("h4", { className: 'prizes-title', children: prize_item === null || prize_item === void 0 ? void 0 : prize_item.name }), _jsxs("h5", { className: 'prizes-winner', children: [prize_item === null || prize_item === void 0 ? void 0 : prize_item.quantity, " ", (prize_item === null || prize_item === void 0 ? void 0 : prize_item.quantity) > 1 ? 'Winners' : 'Winner'] }), _jsx("div", { className: 'prizes-description', dangerouslySetInnerHTML: { __html: prize_description } })] })] })) : (_jsx(Row, { children: _jsxs(Col, { lg: 12, className: 'my-3', children: [_jsx("h4", { className: 'prizes-title', children: prize_item === null || prize_item === void 0 ? void 0 : prize_item.name }), _jsxs("h5", { className: 'prizes-winner', children: [prize_item === null || prize_item === void 0 ? void 0 : prize_item.quantity, " ", (prize_item === null || prize_item === void 0 ? void 0 : prize_item.quantity) > 1 ? 'Winners' : 'Winner'] }), _jsx("div", { dangerouslySetInnerHTML: { __html: prize_description } })] }) }))] }, index));
                        }) }));
                }) }), _jsx(BoxedContent, { note: 'Drawings section', hash: 'drawings', isActive: true, isVisible: drawing_groups === null || drawing_groups === void 0 ? void 0 : drawing_groups.length, title: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.drawingsTitle, children: "Drawings" }), children: drawing_groups.map(function (drawing_group, index) {
                    return (_jsx("div", { children: _jsx(DrawingGroupTable, { drawingGroup: drawing_group, loggedIn: user.loggedIn }) }, index));
                }) }), _jsx(BoxedContent, { note: 'Rules section', hash: 'rules', isActive: true, isVisible: rules, title: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.rulesTitle, children: "Rules" }), children: _jsx("div", { dangerouslySetInnerHTML: { __html: rules } }) }), _jsx(BoxedContent, { note: 'Terms of Use section', hash: 'terms', isActive: true, isVisible: terms, title: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.termsTitle, children: "Terms of Use" }), children: _jsx("div", { dangerouslySetInnerHTML: { __html: terms } }) })] }));
};
export default SweepstakeDetails;
