import React from 'react';
import { connect } from 'react-redux';
import { ContentBox, ContentBoxHead, ContentBoxBody, CMSContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const PageNotFound = ({ user, config, cmsSourceFirebase, actions }) => {
    const page = {
        title: 'Page Not Found',
        className: 'page-not-found',
    };

    return (
        <Layout {...{ page, user, config, cmsSourceFirebase, actions }}>
            <ContentBox variant='theme-red' show='true'>
                <ContentBoxHead>
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.sitewideSettings.pageNotFound.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                </ContentBoxHead>
                <ContentBoxBody>
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.sitewideSettings.pageNotFound.contentHTML'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                </ContentBoxBody>
            </ContentBox>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);
