import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Buffer } from 'buffer';
import { isApp } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const LoginPTA = ({ user, config, cmsSourceFirebase, actions }) => {
    const page = {
        title: 'Login',
        className: 'login',
    };
    const [submitted, setSubmitted] = useState(false);

    let playerData = null;

    //* We need to support both PTA methods
    const ptaVersion = isApp() ? 'v2' : 'v1';

    const getObjectFromURLSearchParams = (param) => {
        const urlParams = new URLSearchParams(window.location.search);

        const hash = decodeURI(urlParams.get(param));
        if (!hash) return false;

        try {
            const decodedStr = new TextDecoder().decode(Buffer.from(hash, 'base64'));
            const decodedObj = JSON.parse(decodedStr ?? '{}');

            if (typeof decodedObj === 'object' && decodedObj !== null) {
                return decodedObj;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    };

    // PTA V1 Authentication
    if (ptaVersion === 'v1') {
        //* Retrieve hash json object from url params
        const hashObject = getObjectFromURLSearchParams('Hash');
        playerData = hashObject && hashObject?.chksum && hashObject?.playerID && hashObject?.email ? { ...hashObject, pta_version: 'v1' } : false;
    }

    // PTA V2 Authentication
    if (ptaVersion === 'v2') {
        const urlParams = new URLSearchParams(window.location.search);

        const hash = urlParams.has('Hash') ? urlParams.get('Hash') : '';
        const iv = urlParams.has('Iv') ? urlParams.get('Iv') : '';
        const data = urlParams.has('Data') ? urlParams.get('Data') : '';

        playerData = hash && iv && data ? { ...{ hash, iv, data }, pta_version: 'v2' } : false;
    }

    useEffect(() => {
        if (playerData && config.config && !user.loggedIn) {
            if (!submitted) {
                setSubmitted(true);
                handleLogin();
                console.log('🟢 Login call: first time');
            } else {
                console.log('🟡 Login call: again');
            }
        }
        //? for debug: console.log('🎁 For debug:', ptaVersion, playerData);
    }, [config?.config, user?.loggedIn]);

    const handleLogin = async () => {
        await actions.userActions.login(playerData);
    };

    return (
        <Layout {...{ page, user, config, cmsSourceFirebase, actions }}>
            {user.loggedIn ? <Redirect to='/enter' /> : null}

            {!user.loggedIn && !playerData && (
                <div className='alert alert-danger text-center entry-alert' role='alert'>
                    Validation error: Player object was sent in an invalid format.
                </div>
            )}

            {!user.loggedIn && user?.errors && (
                <div className='alert alert-danger text-center entry-alert' role='alert'>
                    Login error: {JSON.stringify(user?.errors)}
                </div>
            )}
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPTA);
