import React from 'react';
import { HashLink } from 'react-router-hash-link';

import pluralize from 'pluralize';
import { titleCase } from 'title-case';

const PostClaimBox = ({ imageUrl, name, currency, prize_name, amount, promotionError, isAllocatable = false }) => {
    if (prize_name === 'PointsForPrizes') {
        prize_name = 'Points For Prizes';
    } else if (prize_name === 'PointsForDrawings') {
        prize_name = 'Points For Drawings';
    }

    // Note:  Nothing details links for Experiantial Rewards.
    let viewDetailsLink = '';
    if (currency === 'points') {
        viewDetailsLink = <HashLink to='/my-activity#points-history'>View Points History</HashLink>;
    } else if (currency === 'lotteryproducts') {
        viewDetailsLink = <HashLink to='/my-activity#coupons-history'>View Coupons</HashLink>;
    } else if (currency === 'storepromo') {
        viewDetailsLink = <HashLink to='/my-activity#promo-history'>View Promo Codes</HashLink>;
    } else if (currency === 'sweepstake') {
        viewDetailsLink = isAllocatable ? (
            <HashLink to='/my-activity#allocatable-entries'>View Entries</HashLink>
        ) : (
            <HashLink to='/my-activity#drawings'>View Next Drawing</HashLink>
        );
    }

    // todo handle partial rewards and failures.   Handle Multipliers
    return (
        <>
            <div className='media my-2 text-center claim-box' key={name}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 pl-0'>{imageUrl && <img className='promo-image' src={`${imageUrl}`} alt={`${name} logo`} />}</div>
                        <div className='col-md-6'>
                            <div className='media-body promotion-info-container'>
                                <h2 className='promotion-name'>{name}</h2>
                                {promotionError ? <p className='mt-0 theme-text-danger'>{promotionError}</p> : null}
                                <p className='mt-1 reward-amount'>{amount}</p>
                                <p className='mt-1 reward-currency'>{pluralize(titleCase(prize_name), amount, false)}</p>
                                <p className='mt-1 cta'>{viewDetailsLink}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostClaimBox;
