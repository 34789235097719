var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentCollapser, ContentBoxBody } from '../ContentBox/ContentBox';
import CMSContent from '../CMSContent/CMSContent';
import TeleScript from '../TeleScript/TeleScript';
import isHash from '../../utils/isHash';
import IssueZoneSelect from './IssueZoneSelect';
import FeedbackError from './FeedbackError';
import FeedbackSuccess from './FeedbackSuccess';
import PromotionDropdown from './PromotionDropdown';
var phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
var zipRegExp = /^[0-9]{5}$/;
var emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
var dateRegExp = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d\d\d\d$/;
var defaultFeedbackSchema = Yup.object().shape({
    firstname: Yup.string().required('Please enter your first name.'),
    lastname: Yup.string().required('Please enter your last name.'),
    email: Yup.string().matches(emailRegExp, 'Please enter a valid email address.').required('Please enter your email address.'),
    issueDate: Yup.string().matches(dateRegExp, 'Please provide a valid date (MM/DD/YYYY).').required('Please provide a valid date (MM/DD/YYYY).'),
    zipcode: Yup.string().matches(zipRegExp, 'Please enter a valid ZIP code.').required('Please enter your ZIP code.'),
    phone: Yup.string().matches(phoneRegExp, 'Please enter a valid phone number.').required('Please enter your phone number.'),
    issue_zone: Yup.string().required('Please select one.'),
    issue_category: Yup.string().required('Please select one.'),
    comments: Yup.string().required('Please provide your comments.'),
});
/**
 * Form for user to submit feedback and concerns to the lottery
 *
 * Typically used in the Help section of the site inside of a Layout component. Typically rendered alongside other BoxedContents
 */
var FeedbackForm = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var user = _a.user, actions = _a.actions, feedback = _a.feedback, cmsSourceFirebase = _a.cmsSourceFirebase, loading = _a.loading, feedbackCategories = _a.feedbackCategories, promotion = _a.promotion, telescript = _a.telescript, _r = _a.options, options = _r === void 0 ? {
        variant: 'theme-primary',
        hasCollapser: true,
        hash: 'contact',
    } : _r, validationSchema = _a.validationSchema;
    var initialValues = {
        key: 1,
        firstname: ((_b = user === null || user === void 0 ? void 0 : user.player) === null || _b === void 0 ? void 0 : _b.firstname) || ((_c = user === null || user === void 0 ? void 0 : user.player) === null || _c === void 0 ? void 0 : _c.first_name),
        lastname: ((_d = user === null || user === void 0 ? void 0 : user.player) === null || _d === void 0 ? void 0 : _d.lastname) || ((_e = user === null || user === void 0 ? void 0 : user.player) === null || _e === void 0 ? void 0 : _e.last_name),
        email: (_f = user === null || user === void 0 ? void 0 : user.player) === null || _f === void 0 ? void 0 : _f.email,
        phone: (_k = (_h = (_g = user === null || user === void 0 ? void 0 : user.player) === null || _g === void 0 ? void 0 : _g.phone) !== null && _h !== void 0 ? _h : (_j = user === null || user === void 0 ? void 0 : user.player) === null || _j === void 0 ? void 0 : _j.phone1) !== null && _k !== void 0 ? _k : '',
        zipcode: (_q = (_o = (_m = (_l = user === null || user === void 0 ? void 0 : user.player) === null || _l === void 0 ? void 0 : _l.address) === null || _m === void 0 ? void 0 : _m.zip) !== null && _o !== void 0 ? _o : (_p = user === null || user === void 0 ? void 0 : user.player) === null || _p === void 0 ? void 0 : _p.zip) !== null && _q !== void 0 ? _q : '',
        issueDate: moment().format('MM/DD/YYYY'),
        promotion: (telescript === null || telescript === void 0 ? void 0 : telescript.noPromotionText) || 'No Promotion',
        comments: '',
        issue_zone: '',
        issue_category: '',
        issue_categories: [],
    };
    useEffect(function () {
        actions.feedbackCategoriesActions.getFeedbackCategories();
        actions.feedbackActions.reset();
        actions.promotionActions.getPromotions();
    }, []);
    var handleSubmit = function (formPayload, _a) {
        var setSubmitting = _a.setSubmitting, setStatus = _a.setStatus, resetForm = _a.resetForm;
        return __awaiter(void 0, void 0, void 0, function () {
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        actions.feedbackActions.reset();
                        return [4 /*yield*/, actions.feedbackActions.postFeedback(formPayload)];
                    case 1:
                        _c.sent();
                        if (feedback.success) {
                            resetForm();
                            setStatus(true);
                        }
                        else {
                            setStatus(false);
                        }
                        setSubmitting(false);
                        (_b = document === null || document === void 0 ? void 0 : document.getElementById(options.hash)) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: 'smooth' });
                        return [2 /*return*/];
                }
            });
        });
    };
    var phoneField = function (_a) {
        var field = _a.field, className = _a.className;
        return (_jsx(Cleave, __assign({}, field, { className: className, options: {
                numericOnly: true,
                blocks: [0, 3, 3, 4],
                delimiters: ['(', ') ', '-'],
            } })));
    };
    var issueDateField = function (_a) {
        var field = _a.field, className = _a.className;
        return (_jsx(Cleave, __assign({}, field, { className: className, placeholder: '(MM/DD/YYYY)', options: {
                date: true,
                delimiter: '/',
                datePattern: ['m', 'd', 'Y'],
            } })));
    };
    return (_jsxs(ContentBox, { id: options.hash, show: isHash(options.hash), variant: options.variant, children: [_jsxs(ContentBoxHead, { children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.helpAndFaqs.contactUs.contentHeaderText', cmsSourceFirebase: cmsSourceFirebase }), options.hasCollapser && _jsx(ContentCollapser, { children: undefined })] }), _jsx(ContentBoxBody, { children: !loading.actions.getPlayerData ? (_jsx("div", { className: 'row', children: _jsxs("div", { className: 'col-lg-8 offset-lg-2', children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.helpAndFaqs.contactUs.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), _jsx(FeedbackError, { isError: feedback.isError, error: feedback.error }), _jsx(FeedbackSuccess, { success: feedback.success, telescript: telescript }), _jsx(Formik, { initialValues: initialValues, validationSchema: validationSchema !== null && validationSchema !== void 0 ? validationSchema : defaultFeedbackSchema, onSubmit: handleSubmit, children: function (_a) {
                                    var values = _a.values, errors = _a.errors, touched = _a.touched, handleBlur = _a.handleBlur, isSubmitting = _a.isSubmitting, setFieldValue = _a.setFieldValue;
                                    return (_jsxs(Form, { noValidate: true, name: 'feedback_form', id: 'feedback_form', method: 'post', encType: 'application/x-www-form-urlencoded', children: [_jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'email', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.emailFieldLabel, children: "Email*" }) }), _jsx(Field, { disabled: isSubmitting, name: 'email', type: 'text', id: 'email', className: 'form-control theme-input' + (errors.email && touched.email ? ' is-invalid' : ''), size: '60', maxLength: '60', value: values.email, onBlur: function (e) {
                                                            handleBlur(e);
                                                            actions.feedbackActions.reset();
                                                        } }), _jsx(ErrorMessage, { name: 'email', component: 'div', className: 'invalid-input text-danger' })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'firstname', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.firstNameLabel, children: "First Name*" }) }), _jsx(Field, { disabled: isSubmitting, name: 'firstname', type: 'text', id: 'firstname', className: 'form-control theme-input' + (errors.firstname && touched.firstname ? ' is-invalid' : ''), size: '120', maxLength: '120', value: values.firstname, onBlur: function (e) {
                                                            handleBlur(e);
                                                            actions.feedbackActions.reset();
                                                        } }), _jsx(ErrorMessage, { name: 'firstname', component: 'div', className: 'invalid-input text-danger' })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'lastname', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.lastNameLabel, children: "Last Name*" }) }), _jsx(Field, { disabled: isSubmitting, name: 'lastname', type: 'text', id: 'lastname', className: 'form-control theme-input' + (errors.lastname && touched.lastname ? ' is-invalid' : ''), size: '120', maxLength: '120', value: values.lastname, onBlur: function (e) {
                                                            handleBlur(e);
                                                            actions.feedbackActions.reset();
                                                        } }), _jsx(ErrorMessage, { name: 'lastname', component: 'div', className: 'invalid-input text-danger' })] }), _jsxs("div", { className: 'form-row', children: [_jsxs("div", { className: 'col-md-6 form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'zipcode', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.zipCodeLabel, children: "ZIP Code*" }) }), _jsx(Field, { disabled: isSubmitting, name: 'zipcode', type: 'text', id: 'zipcode', className: 'form-control theme-input' + (errors.zipcode && touched.zipcode ? ' is-invalid' : ''), size: '5', maxLength: '5', value: values.zipcode, onBlur: function (e) {
                                                                    handleBlur(e);
                                                                    actions.feedbackActions.reset();
                                                                } }), _jsx(ErrorMessage, { name: 'zipcode', component: 'div', className: 'invalid-input text-danger' })] }), _jsxs("div", { className: 'col-md-6 form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'phone', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.phoneLabel, children: "Phone*" }) }), _jsx(Field, { component: phoneField, disabled: isSubmitting, name: 'phone', id: 'phone', className: 'form-control theme-input' + (errors.phone && touched.phone ? ' is-invalid' : ''), value: values.phone, onBlur: function (e) {
                                                                    handleBlur(e);
                                                                    actions.feedbackActions.reset();
                                                                } }), _jsx(ErrorMessage, { name: 'phone', component: 'div', className: 'invalid-input text-danger' })] })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'issueDate', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.dateOfIssueLabel, children: "Date of Issue*" }) }), _jsx(Field, { component: issueDateField, disabled: isSubmitting, name: 'issueDate', id: 'issueDate', className: 'form-control theme-input' + (errors.issueDate && touched.issueDate ? ' is-invalid' : ''), value: values.issueDate, onBlur: function (e) {
                                                            handleBlur(e);
                                                            actions.feedbackActions.reset();
                                                        } }), _jsx(ErrorMessage, { name: 'issueDate', component: 'div', className: 'invalid-input text-danger' })] }), _jsx("div", { className: 'form-row', children: _jsxs("div", { className: 'col form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'promotion', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.promotionLabel, children: "Promotion (Optional)" }) }), _jsx(PromotionDropdown, { promotions: promotion.promotions, values: values, isSubmitting: isSubmitting, telescript: telescript })] }) }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'issue_zone', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.categoryOfIssueLabel, children: "Category of Issue*" }) }), _jsx(IssueZoneSelect, { values: values, errors: errors, touched: touched, isSubmitting: isSubmitting, categories: feedbackCategories.feedbackCategories.body, setFieldValue: setFieldValue, actions: actions, telescript: telescript })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'issue_category', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.issueDetailLabel, children: "Issue Detail*" }) }), _jsxs(Field, { disabled: isSubmitting, as: 'select', className: 'form-control theme-select text-and-select' +
                                                            (errors.issue_category && touched.issue_category ? ' is-invalid' : ''), name: 'issue_category', id: 'issue_category', value: values.issue_category, onBlur: function (e) {
                                                            handleBlur(e);
                                                            actions.feedbackActions.reset();
                                                        }, children: [_jsx("option", { value: '', children: (telescript === null || telescript === void 0 ? void 0 : telescript.issueDetailLabel) || 'Issue Detail' }, 'none'), values.issue_categories &&
                                                                values.issue_categories.map(function (categoryName) { return (_jsx("option", { value: categoryName, children: categoryName }, "category-".concat(categoryName))); })] }), _jsx(ErrorMessage, { name: 'issue_category', component: 'div', className: 'invalid-input text-danger' })] }), _jsx("div", { className: 'form-row', children: _jsxs("div", { className: 'col form-group', children: [_jsx("label", { className: 'form-label', htmlFor: 'comments', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.commentsLabel, children: "Describe your issue in as much detail as possible.*" }) }), _jsx(Field, { disabled: isSubmitting, name: 'comments', as: 'textarea', id: 'comments', className: 'form-control theme-textarea' + (errors.comments && touched.comments ? ' is-invalid' : ''), rows: '10', cols: '50', wrap: 'soft', maxLength: '1200', value: values.comments, placeholder: 'Describe your issue', onBlur: function (e) {
                                                                handleBlur(e);
                                                                actions.feedbackActions.reset();
                                                            } }), _jsx(ErrorMessage, { name: 'comments', component: 'div', className: 'invalid-input text-danger' }), _jsx("small", { className: 'form-text', children: "1200 character max" })] }) }), _jsx("div", { className: 'form-row', children: _jsx("div", { className: 'col form-group', children: _jsx("small", { className: 'form-text text-muted', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.requiredFieldText, children: "* Denotes required fields." }) }) }) }), _jsx("div", { className: 'form-row', children: _jsxs("div", { className: 'col submit-div mb-4 text-center form-group', children: [_jsx(Button, { disabled: isSubmitting, id: 'entry-submit', name: 'submit', type: 'submit', className: 'btn theme-btn theme-secondary btn-lg', variant: 'theme', children: isSubmitting ? (_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.loadingText, children: "Loading..." })) : (_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.submitText, children: "Submit" })) }), _jsx(ErrorMessage, { name: 'submit', component: 'div', className: 'invalid-input text-danger' })] }) })] }));
                                } }), _jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.helpAndFaqs.contactUsFooter.contentHTML', cmsSourceFirebase: cmsSourceFirebase })] }) })) : (_jsx(_Fragment, {})) })] }));
};
export default FeedbackForm;
