import React from 'react';
import { Button } from 'react-bootstrap';

export default class ScannerIGT extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            requestMade: false,
        };
    }

    handleMessage = (params) => {
        if (params && params.data.message === 'scanResults') {
            if (params.data.error) {
                console.error('params.data.error:', params.data.error);
                this.props.updateScannerError(params.data.error);
            } else if (params.data.ticket) {
                console.log('params.data.ticket:', params.data.ticket);
                this.props.updateScanStatus(true);
                this.props.updateEntryForm(params.data.ticket);
            }

            this.setState({ requestMade: false });
            window.removeEventListener('message', this.handleMessage, false);
            return;
        }
    };

    doScan = () => {
        if (!this.state.requestMade) {
            console.log('postMessage: scanTicket');
            // don't double up in case of delays
            this.setState({ requestMade: true });

            window.addEventListener('message', this.handleMessage, false);
            window.parent.postMessage({ message: 'scanTicket' }, '*');
        }
    };

    render() {
        return (
            <Button onClick={this.doScan} id='scan-btn' className={this.props.className ?? 'btn-theme'} variant='theme' disabled={this.state.requestMade} block>
                {this.state.requestMade ? 'Scanning...' : 'Scan Ticket'}
            </Button>
        );
    }
}
