var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';
export var defaultAchievementState = {
    lastRequested: null,
    requestError: false,
    pageSize: 5,
    page: 0,
    total: 0,
    rewards: [],
};
export var sampleAchievementState = {
    lastRequested: null,
    requestError: false,
    pageSize: 5,
    page: 0,
    total: 0,
    rewards: [
        {
            id: 329,
            promotion_id: 440,
            signal_audit_log_id: 440,
            player_id: 440,
            prize_id: 440,
            state: 10000,
            intent_amount: 9000,
            actual_amount: 500,
            prize_name: '$2 Scratcher',
            prize_description: 'A long description of a $2 scratcher',
            value: 5000,
            currency: 'points',
        },
    ],
};
// REDUCERS
// basic actions that affect this object only
var getAchievementsReducer = function (state, action) {
    var _a = action.payload, newRewards = _a.rewards, page = _a.page, total = _a.total;
    // TODO: There's a bug in the API that causes more results than expected to be returned, truncate it for now
    if (newRewards && newRewards.length > state.pageSize) {
        newRewards.length = state.pageSize;
    }
    // Merge newRewards in existing rewards making sure that they're slotted in the
    // right place (potentially overwriting existing records if the API messed up)
    var rewards = __spreadArray([], state.rewards, true);
    rewards.length = state.pageSize * (page - 1);
    newRewards && rewards.push.apply(rewards, newRewards);
    return __assign(__assign({}, state), { lastRequested: Date.now(), rewards: rewards, 
        // TODO: There's a bug in the API where these values are returned as strings instead of numbers
        page: Number(page), total: Number(total) });
};
var setRequestErrorReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), requestError: true });
};
export var achievementSlice = createSlice({
    name: 'achievement',
    initialState: defaultAchievementState,
    reducers: {
        getAchievements: getAchievementsReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: (_a = {},
        _a[userSlice.actions.logout] = function (state) {
            return defaultAchievementState;
        },
        _a),
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getAchievements = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getEntries' }));
                return [4 /*yield*/, apiClient().getAchievements()];
            case 1:
                result = _a.sent();
                console.log(result);
                dispatch(achievementSlice.actions.getAchievements(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getEntries' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var achievementActions = function (dispatch) {
    return {
        getAchievements: function (payload) { return getAchievements(dispatch, payload, apiClient); },
    };
};
