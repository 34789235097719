import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ContentBox, ContentBoxHead, ContentCollapser, ContentBoxBody } from '../ContentBox/ContentBox';
import { CMSContent } from '../CMSContent/CMSContent';
import isHash from '../../utils/isHash';
var ScratchNumberExample = function (_a) {
    var cmsSourceFirebase = _a.cmsSourceFirebase;
    return (_jsxs(_Fragment, { children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), _jsxs("div", { className: 'row', children: [_jsxs("div", { className: 'col-lg-6', children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.scratchPanelLeft.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), _jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.scratchPanelLeft.image', className: 'img-fluid mx-auto d-block', cmsSourceFirebase: cmsSourceFirebase })] }), _jsxs("div", { className: 'col-lg-6', children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.scratchPanelRight.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), _jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.scratchPanelRight.image', className: 'img-fluid mx-auto d-block', cmsSourceFirebase: cmsSourceFirebase })] })] })] }));
};
var FindScratchNumbers = function (_a) {
    var cmsSourceFirebase = _a.cmsSourceFirebase;
    var hash = 'scrach_ticket_sample';
    return (_jsxs(ContentBox, { variant: 'theme-primary', id: hash, show: isHash(hash), children: [_jsxs(ContentBoxHead, { children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.contentHeaderText', cmsSourceFirebase: cmsSourceFirebase }), _jsx(ContentCollapser, { children: undefined })] }), _jsx(ContentBoxBody, { children: _jsx(ScratchNumberExample, { cmsSourceFirebase: cmsSourceFirebase }) })] }));
};
export default {
    FindScratchNumbers: FindScratchNumbers,
    ScratchNumberExample: ScratchNumberExample,
};
