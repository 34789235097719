// TODO: Move this to sg-ui-components since it's also used in SCL + DWB
export const scrollToHashElement = () => {
    const { hash } = window.location;
    const elementToScroll = document.getElementById(hash?.replace('#', ''));
    if (!elementToScroll) return;

    window.scrollTo({
        top: elementToScroll.offsetTop,
        behavior: 'smooth',
    });
};
