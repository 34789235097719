var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';
export var defaultCouponState = {
    lastRequested: null,
    requestError: false,
    pageSize: 5,
    page: 1,
    total: 0,
    coupons: [],
};
// REDUCERS
// basic actions that affect this object only
var getCouponReducer = function (state, action) {
    var _a, _b, _c;
    var data = action.payload;
    var newCoupons = data.CouponDetail;
    var page = (_a = data.page) !== null && _a !== void 0 ? _a : 1;
    var pageSize = (_b = data.pageSize) !== null && _b !== void 0 ? _b : 5;
    var total = (_c = data.total) !== null && _c !== void 0 ? _c : 0;
    if (newCoupons || page > 1) {
        // Merge NewCoupons in existing Coupons.
        var coupons = JSON.parse(JSON.stringify(state.coupons));
        newCoupons && coupons.push.apply(coupons, newCoupons);
        return __assign(__assign({}, state), { lastRequested: Date.now(), coupons: coupons, page: page, pageSize: pageSize, total: total });
    }
    else {
        return state;
    }
};
var clearCouponReducer = function (state, action) {
    return defaultCouponState;
};
var resetReducer = function (state, action) {
    return __assign({}, defaultCouponState);
};
var setRequestErrorReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), requestError: true });
};
export var couponSlice = createSlice({
    name: 'coupon',
    initialState: defaultCouponState,
    reducers: {
        getCoupons: getCouponReducer,
        clearCoupon: clearCouponReducer,
        reset: resetReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: (_a = {},
        _a[userSlice.actions.logout] = function (state) {
            return defaultCouponState;
        },
        _a),
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getCoupons = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getCoupons' }));
                return [4 /*yield*/, apiClient().getCoupons(payload)];
            case 1:
                result = _a.sent();
                dispatch(couponSlice.actions.getCoupons(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getCoupons' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var couponActions = function (dispatch, apiClient) {
    return {
        getCoupons: function (payload) { return getCoupons(dispatch, payload, apiClient); },
        clearCoupons: function (payload) { return dispatch(couponSlice.actions.clearCoupons(payload)); },
        reset: function (payload) { return dispatch(couponSlice.actions.reset(payload)); },
    };
};
