import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './content_box_style.scss';
import { noop } from 'lodash';
var CollapseId = React.createContext(0);
var CollapseShow = React.createContext({ show: true, toggle: noop });
/**
 * A box of content.
 */
var ContentBox = function (_a) {
    var children = _a.children, _b = _a.show, show = _b === void 0 ? true : _b, _c = _a.variant, variant = _c === void 0 ? '' : _c, _d = _a.id, id = _d === void 0 ? '' : _d;
    // the default state is open, so we look for intentionally set 'false'
    var _e = useState(show !== 'false' ? true : false), showing = _e[0], setShowing = _e[1];
    // if we are using a hash link, that will set focus on the box
    // if it is collapsed, we want to uncollapse it
    var handleFocus = function () {
        setShowing(true);
    };
    useEffect(function () {
        if (typeof show === 'string') {
            show = show !== 'false';
        }
        setShowing(show);
    }, [show]);
    // we need to know if the content is hidden on the parent element (.content-box)
    // so that we can style the header with correct colors / border radii
    return (_jsx("div", { className: "content-box ".concat(variant, " ").concat(showing ? '' : 'content-is-hidden'), id: id, onFocus: handleFocus, children: _jsx(CollapseId.Provider, { value: Math.floor(Math.random() * 100), children: _jsx(CollapseShow.Provider, { value: {
                    show: showing,
                    toggle: function () {
                        setShowing(!showing);
                    },
                }, children: children }) }) }));
};
var ContentCollapser = function (_a) {
    var children = _a.children, upArrow = _a.upArrow, downArrow = _a.downArrow;
    var _b = React.useContext(CollapseShow), show = _b.show, toggle = _b.toggle;
    // if no children are set as the link element child, use an svg arrow down/up
    return (_jsx("a", { className: "float-right collapser ".concat(show ? 'showing' : 'hiding'), role: 'button', onClick: toggle, children: children && Array.isArray(children) ? (_jsx("span", { children: children })) : show ? (_jsx("span", { className: 'toggle-control', children: upArrow || _jsx(FaChevronUp, {}) })) : (_jsx("span", { className: 'toggle-control', children: downArrow || _jsx(FaChevronDown, {}) })) }));
};
var ContentBoxHead = function (_a) {
    var children = _a.children;
    return _jsx("div", { className: 'content-box-head', children: children });
};
var ContentBoxBody = function (_a) {
    var children = _a.children;
    var id = React.useContext(CollapseId);
    var show = React.useContext(CollapseShow).show;
    return (_jsx("div", { className: "content-box-body collapse ".concat(show ? 'show' : ''), id: "collapse".concat(id), children: children }));
};
export { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser };
