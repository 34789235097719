import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FaRegTimesCircle, FaRegCheckCircle } from 'react-icons/fa';
import { Alert } from 'react-bootstrap';
/**
 * Small box to show user an alert with a message.
 */
var AlertBox = function (_a) {
    var variant = _a.variant, message = _a.message, extraClassNames = _a.extraClassNames;
    if (!message)
        return null;
    return (_jsxs(Alert, { "data-testid": 'alert-box', variant: variant, className: " ".concat(extraClassNames), children: [variant == 'danger' ? _jsx(FaRegTimesCircle, {}) : _jsx(FaRegCheckCircle, {}), _jsx("div", { className: 'alert-text', dangerouslySetInnerHTML: { __html: String(message) } })] }));
};
AlertBox.defaultProps = {
    variant: 'danger',
    message: null,
    extraClassNames: '',
};
export default AlertBox;
