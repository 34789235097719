import React from 'react';
import { FindScratchNumbers } from 'sg-ui-components';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

const TicketSamples = ({ cmsSourceFirebase }) => {
    return (
        <div className='row justify-content-center'>
            <div className='col-12'>
                <FindScratchNumbers.FindScratchNumbers cmsSourceFirebase={cmsSourceFirebase} />
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketSamples);
