var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, TeleScript, calculateContentBoxShow, TableMobile, TableDesktop, formatDate, } from '../../../index';
import './my-allocatable-entries.scss';
import { Button } from 'react-bootstrap';
import './my-allocatable-entries.scss';
import AllocatableModal from './AllocatableModal';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
/**********************************************************************
 * Component: MyAllocatableEntries
 * Purpose:   Shows all the player's outstanding entries they can allocate
 *            for all sweepstakes.    Part of the My Activity page
 *
 * Props: - actions       -  actions table for api calls
 *          sweep         -  reference to the parent Sweepstake
 *          loading       -  reference to the loading store
 *          myAllocatableTelescript -  Telescript to allow for content editing via CMS
 *
 *
 * APIs used:  /sweepstake/entry/allocatable - get players allocatable entries
 *             /sweepstake/current -  gets list of current sweepstakes
 */
var MyAllocatableEntries = function (_a) {
    var _b, _c;
    var actions = _a.actions, sweep = _a.sweep, loading = _a.loading, telescript = _a.telescript, _d = _a.AllocatableForm, AllocatableForm = _d === void 0 ? function () { return _jsx(_Fragment, {}); } : _d, _e = _a.options, options = _e === void 0 ? {
        variant: 'theme-primary',
        hasCollapser: true,
        hash: 'allocatable-entries',
    } : _e;
    var _f = useState(false), pageLoaded = _f[0], setPageLoaded = _f[1];
    var _g = useState(false), hasEntries = _g[0], setHasEntries = _g[1];
    var hash = (_b = options === null || options === void 0 ? void 0 : options.hash) !== null && _b !== void 0 ? _b : 'allocatable-entries';
    //***********************************************************************************
    // On load get the both the list of current sweeps and all player's allocatable
    // entries.
    //
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 3];
                            return [4 /*yield*/, actions.sweepActions.getPlayerAllocatableEntries()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, actions.sweepActions.getSweeps()];
                        case 2:
                            _a.sent();
                            setPageLoaded(true);
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
        return function () {
            setPageLoaded(false);
            setHasEntries(false);
        };
    }, []);
    useEffect(function () {
        var _a;
        if ((_a = sweep === null || sweep === void 0 ? void 0 : sweep.playerAllocatableEntries) === null || _a === void 0 ? void 0 : _a.length) {
            setHasEntries(true);
        }
    }, [sweep]);
    var Details = function (_a) {
        var entry = _a.entry, telescript = _a.telescript;
        var _b = useState(false), show = _b[0], setShow = _b[1];
        var showModal = function () {
            setShow(true);
        };
        return (_jsxs("div", { children: [_jsx(Button, { variant: 'link', className: 'theme-btn-link p-0', onClick: function () { return showModal((entry = { entry: entry })); }, children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.buttonDetails, children: "Assign Now" }) }), _jsx(AllocatableModal, { AllocatableForm: AllocatableForm, sweep: sweep, actions: actions, telescript: telescript, show: show, setShow: setShow, entry: entry })] }, entry.id));
    };
    var EntriesTable = function (_a) {
        var _b, _c, _d, _e, _f;
        var data = _a.data, telescript = _a.telescript, pageLoaded = _a.pageLoaded;
        var entries = Array.isArray(data) ? data : [];
        var displayHeaders = {
            drawing: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.tableDrawing) !== null && _b !== void 0 ? _b : 'Second-Chance Promotion',
            allocateBy: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableAllocateBy) !== null && _c !== void 0 ? _c : 'Assign by*',
            entriesToAllocate: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntriesToAllocate) !== null && _d !== void 0 ? _d : 'Entries to Allocate',
            details: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.tableDetails) !== null && _e !== void 0 ? _e : 'Details',
        };
        var displayRows = __spreadArray([], entries, true).map(function (entry) {
            var _a, _b;
            return (__assign(__assign({}, entry), { fields: {
                    drawing: (_a = entry === null || entry === void 0 ? void 0 : entry.sweepstake_name) !== null && _a !== void 0 ? _a : 'N/A',
                    allocateBy: formatDate === null || formatDate === void 0 ? void 0 : formatDate.dateTimeWithMeridiem(entry === null || entry === void 0 ? void 0 : entry.allocate_by),
                    entriesToAllocate: (_b = entry === null || entry === void 0 ? void 0 : entry.quantity) !== null && _b !== void 0 ? _b : 'N/A',
                    details: _jsx(Details, { telescript: telescript, entry: entry }, entry === null || entry === void 0 ? void 0 : entry.id),
                } }));
        });
        var displayOptions = {
            tableId: 'allocatable-entries-table',
            isLoaded: pageLoaded,
            messageNoRows: (_f = telescript === null || telescript === void 0 ? void 0 : telescript.messageNoRows) !== null && _f !== void 0 ? _f : 'You currently have no second-chances entries to assign',
        };
        return (_jsxs(_Fragment, { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
    };
    if (loading.actions['allocateEntries']) {
        return null;
    }
    else {
        return (_jsxs(ContentBox, { variant: (_c = options === null || options === void 0 ? void 0 : options.variant) !== null && _c !== void 0 ? _c : 'theme-primary', id: hash, show: calculateContentBoxShow(__assign(__assign({}, options), { hash: hash, hasEntries: hasEntries })), children: [_jsxs(ContentBoxHead, { children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.title, children: "My Allocatable Entries" }), (options === null || options === void 0 ? void 0 : options.hasCollapser) === true && (_jsx(ContentCollapser, { upArrow: options.upArrow, downArrow: options.downArrow }))] }), _jsx(ContentBoxBody, { children: _jsx("div", { className: 'row', children: _jsxs("div", { className: 'col', children: [_jsx(EntriesTable, { data: sweep === null || sweep === void 0 ? void 0 : sweep.playerAllocatableEntries, telescript: telescript, pageLoaded: pageLoaded }), _jsx("div", { className: 'text-center', children: _jsx("small", { children: _jsx(TeleScript, { line: telescript.footnote, children: "* All unassigned entries are entered into the default prize for that drawing." }) }) })] }) }) })] }));
    }
};
export default MyAllocatableEntries;
