import moment from 'moment';
// check if the date is xyz years in the future
/// TODO:Refactor input date should be Date type
export var isFutureDate = function (inputDate, yearsFuture) {
    var futureDate = yearsFuture ? moment().add(yearsFuture, 'y') : moment().add(35, 'y');
    return moment.unix(inputDate).isAfter(futureDate) ? true : false;
};
// format date functionality (pure dates and datetimes)
export var formatDate = {
    // format date to unix:
    convertDateToUnix: function (inputDate) {
        return moment(inputDate).unix();
    },
    // format date to custom format (use this when you need to experiment a new type of datetime before creating a new function)
    //? Note: currently you can use format what Moment.js format function supports:
    // https://momentjs.com/docs/#/parsing/string-format
    custom: function (inputDate, format) {
        if (format === void 0) { format = 'MMM DD, YYYY'; }
        return moment.unix(inputDate).format(format);
    },
    // format date to: Feb 24, 2022
    monthDayYear: function (inputDate) {
        return moment.unix(inputDate).format('MMM DD, YYYY');
    },
    // format date to: 02/24/2022
    monthDayYearWithSlashes: function (inputDate) {
        return moment.unix(inputDate).format('MM/DD/YYYY');
    },
    // format date to: 24/02/2022
    dayMonthYear: function (inputDate) {
        return moment.unix(inputDate).format('DD/MM/YYYY');
    },
    // format date to: Feb 24, 2022 23:59:59
    dateTimeMilitary: function (inputDate) {
        return moment.unix(inputDate).format('MMM DD, YYYY kk:mm');
    },
    // format date to: Feb 24, 2022 23:59:59 AM
    dateTimeMilitaryWithMeridiem: function (inputDate) {
        return moment.unix(inputDate).format('MMM DD, YYYY kk:mm A');
    },
    // format date to: Thu., Feb 24, 2022
    dateWithDayOfTheWeek: function (inputDate) {
        return moment.unix(inputDate).format('ddd., MMM DD, YYYY');
    },
    // format date to: Mon., Feb 24, 2022 11:59 PM
    dateTimeWithDayOfTheWeek: function (inputDate) {
        return moment.unix(inputDate).format('ddd., MMM DD, YYYY hh:mm A');
    },
    // format date to: Feb 24, 2022 23:59:59
    dateTimeWithSeconds: function (inputDate) {
        return moment.unix(inputDate).format('MMM DD, YYYY kk:mm:ss');
    },
    // format date to: Feb 24, 2022 11:59 PM
    dateTimeWithMeridiem: function (inputDate) {
        return moment.unix(inputDate).format('MMM DD, YYYY hh:mm A');
    },
};
// format date with TBD functionality for future dates
//? Note: we need to use this future TBD feature, because sometimes the Lottery does not know when they want to schedule the drawing date or the entry period when a promotion starts
export var formatDateTBD = {
    // format date to: TBD or unix:
    convertDateToUnix: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        /// TODO:Refactor input date to Date type
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.convertDateToUnix(inputDate);
    },
    // format date to custom format (use this when you need to experiment a new type of datetime before creating a new function)
    //? Note: currently you can use format what Moment.js format function supports:
    // https://momentjs.com/docs/#/parsing/string-format
    custom: function (inputDate, yearsFuture, format) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        if (format === void 0) { format = 'MMM DD, YYYY'; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.custom(inputDate, format);
    },
    // format date to: TBD or Feb 24, 2022
    monthDayYear: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.monthDayYear(inputDate);
    },
    // format date to: TBD or 02/24/2022
    monthDayYearWithSlashes: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.monthDayYearWithSlashes(inputDate);
    },
    // format date to: TBD or 24/02/2022
    dayMonthYear: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.dayMonthYear(inputDate);
    },
    // format date to: TBD or Feb 24, 2022 23:59:59
    dateTimeMilitary: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.dateTimeMilitary(inputDate);
    },
    // format date to: TBD or Feb 24, 2022 23:59:59 AM
    dateTimeMilitaryWithMeridiem: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.dateTimeMilitaryWithMeridiem(inputDate);
    },
    // format date to: TBD or Thu., Feb 24, 2022
    dateWithDayOfTheWeek: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.dateWithDayOfTheWeek(inputDate);
    },
    // format date to: TBD or Mon., Feb 24, 2022 11:59 PM
    dateTimeWithDayOfTheWeek: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.dateTimeWithDayOfTheWeek(inputDate);
    },
    // format date to: TBD or Feb 24, 2022 23:59:59
    dateTimeWithSeconds: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.dateTimeWithSeconds(inputDate);
    },
    // format date to: TBD or Feb 24, 2022 11:59 PM
    dateTimeWithMeridiem: function (inputDate, yearsFuture) {
        if (yearsFuture === void 0) { yearsFuture = null; }
        return isFutureDate(inputDate, yearsFuture) ? 'TBD' : formatDate.dateTimeWithMeridiem(inputDate);
    },
};
