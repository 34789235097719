var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Carousel, Col } from 'react-bootstrap';
import { FaAngleRight } from 'react-icons/fa';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox/ContentBox';
import TeleScript from '../TeleScript/TeleScript';
/**
 * An array of promotions where users can click into to learn more.
 *
 * ## Telescript info
 *
 * ### For Home page:
 *
 * const telescriptHomePromotions = {
 *   "title": "NEXT DRAWING",
 *   "see_all": "See all",
 *   "more_info": "More Info",
 * };
 *
 * ### For Promotions page:
 *
 * const telescriptCurrentPromotions = {
 *   "title": "NEXT DRAWING",
 *   "see_all": "See all",
 *   "more_info": "More Info",
 * };
 */
var CurrentSweepstakes = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var sweep = _a.sweep, actions = _a.actions, _h = _a.telescript, telescript = _h === void 0 ? {} : _h, _j = _a.mode, mode = _j === void 0 ? 'all-current-promotions' : _j, _k = _a.promotionLink, promotionLink = _k === void 0 ? '/promotions' : _k, _l = _a.mobileDisplayType, mobileDisplayType = _l === void 0 ? 'carousel' : _l, _m = _a.ProgressiveJackpot, ProgressiveJackpot = _m === void 0 ? function () { return _jsx(_Fragment, {}); } : _m, _o = _a.options, options = _o === void 0 ? {
        id: 'current-sweepstakes',
        variant: 'theme-primary',
        hasCollapser: true,
        interval: 5000,
        limit: 3,
    } : _o;
    var _p = useState(false), pageLoaded = _p[0], setPageLoaded = _p[1];
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 2];
                            return [4 /*yield*/, actions.sweepActions.getCurrentSweeps()];
                        case 1:
                            _a.sent();
                            setPageLoaded(true);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        void fetchData();
    }, []);
    //* Validation check
    var valid = ((_c = (_b = sweep === null || sweep === void 0 ? void 0 : sweep.current) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) > 0;
    if (!valid)
        return null;
    var currentSweeps = __spreadArray([], ((_d = sweep === null || sweep === void 0 ? void 0 : sweep.current) !== null && _d !== void 0 ? _d : []), true);
    //* We need to display limited number of promotions in top3_with_show_more mode
    if (mode === 'top3_with_show_more') {
        currentSweeps = currentSweeps.slice(0, (_e = options.limit) !== null && _e !== void 0 ? _e : 3);
    }
    var validateSweepstake = function (sweepstake) { var _a, _b; return (sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.id) > 0 && (sweepstake === null || sweepstake === void 0 ? void 0 : sweepstake.name) !== '' && ((_a = sweepstake.extra) === null || _a === void 0 ? void 0 : _a.image) !== '' && ((_b = sweepstake.extra) === null || _b === void 0 ? void 0 : _b.description) !== ''; };
    var SweepsCard = function (_a) {
        var _b, _c, _d;
        var id = _a.id, name = _a.name, extra = _a.extra;
        var jackpot_widget = (_b = extra === null || extra === void 0 ? void 0 : extra.jackpot_widget) !== null && _b !== void 0 ? _b : [];
        var jackpots = Array.isArray(jackpot_widget) ? jackpot_widget : (_c = JSON.parse(jackpot_widget)) !== null && _c !== void 0 ? _c : [];
        return (_jsx(Col, { lg: 4, children: _jsxs(Card, { className: 'promotion-card', children: [_jsxs("div", { className: 'progressive-jackpot-container-sweepstake position-relative', children: [(jackpots === null || jackpots === void 0 ? void 0 : jackpots.length) ? _jsx(ProgressiveJackpot, { jackpots: jackpots }) : null, _jsx(Card.Img, { variant: 'top', src: extra === null || extra === void 0 ? void 0 : extra.image, className: 'img-fluid' })] }), _jsxs(Card.Body, { className: 'promotion-body', children: [_jsx(Card.Title, { className: 'promotion-title', children: name }), _jsx(Card.Text, { className: 'promotion-description', children: _jsx("span", { dangerouslySetInnerHTML: { __html: (_d = extra === null || extra === void 0 ? void 0 : extra.short_description) !== null && _d !== void 0 ? _d : extra === null || extra === void 0 ? void 0 : extra.description } }) })] }), _jsx("div", { className: 'more-info justify-content-end', children: _jsx("div", { className: 'more-link', children: _jsxs(Link, { to: "second-chance-promotion?id=".concat(id), className: 'more-info-link', children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.more_info, children: "More Info" }), " ", _jsx(FaAngleRight, { className: 'more-link-icon' })] }) }) })] }) }, id));
    };
    var DesktopList = function () { return (_jsx("div", { className: 'sweeps-list d-none d-lg-flex row', children: currentSweeps.filter(validateSweepstake).map(function (_a) {
            var id = _a.id, name = _a.name, extra = _a.extra;
            return (_jsx(SweepsCard, { id: id, name: name, extra: extra }, id));
        }) })); };
    var MobileList = function () { return (_jsx("div", { "data-testid": 'mobile-list', className: 'sweeps-list d-lg-none row', children: currentSweeps.filter(validateSweepstake).map(function (_a) {
            var id = _a.id, name = _a.name, extra = _a.extra;
            return (_jsx(SweepsCard, { id: id, name: name, extra: extra }, id));
        }) })); };
    var MobileCarousel = function () { return (_jsx(Carousel, { "data-testid": 'mobile-carousel', className: 'sweeps-list overflow-hidden d-lg-none', indicators: currentSweeps.length > 1, controls: currentSweeps.length > 1, children: currentSweeps.filter(validateSweepstake).map(function (_a) {
            var _b;
            var id = _a.id, name = _a.name, extra = _a.extra;
            return (_jsx(Carousel.Item, { className: 'text-center no-gutters', interval: (_b = options.interval) !== null && _b !== void 0 ? _b : 5000, children: _jsx(SweepsCard, { id: id, name: name, extra: extra }) }, id));
        }) })); };
    return (_jsxs(ContentBox, { id: (_f = options === null || options === void 0 ? void 0 : options.id) !== null && _f !== void 0 ? _f : 'current-sweepstakes', variant: (_g = options === null || options === void 0 ? void 0 : options.variant) !== null && _g !== void 0 ? _g : 'theme-primary', children: [_jsxs(ContentBoxHead, { children: [_jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.title, children: "Promotions" }), (options === null || options === void 0 ? void 0 : options.hasCollapser) === true ? _jsx(ContentCollapser, {}) : ''] }), _jsxs(ContentBoxBody, { children: [_jsx(DesktopList, {}), mobileDisplayType === 'carousel' && currentSweeps.length > 1 ? _jsx(MobileCarousel, {}) : _jsx(MobileList, {}), mode === 'top3_with_show_more' && (_jsx("div", { className: 'show-more-section text-center', children: _jsx(Link, { id: 'show_more_button', to: promotionLink !== null && promotionLink !== void 0 ? promotionLink : '/promotions', className: 'btn theme-btn theme-outline', children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.see_all, children: "See all" }) }) }))] })] }));
};
export default CurrentSweepstakes;
