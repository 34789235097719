import React from 'react';
import { connect } from 'react-redux';
import { stripsTagsAndSlashes, getCMSObject, SweepstakeDetails, DrawingGroupTable } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';
import Layout from '../Components/Layout';

import { routes } from '../promotionConfig';

const SecondChancePromotion = ({ user, config, sweep, cmsSourceFirebase, actions, match }) => {
    const path = match.path.replace('-', '');
    const data = routes[stripsTagsAndSlashes(path)];

    const page = {
        title: data.title,
        className: data.className,
    };

    const telescriptSweepstakeDetails = getCMSObject('data.components.teleScripts.sweepstakeDetails.jsonBlock');
    const telescriptDrawingGroupTable = getCMSObject('data.components.teleScripts.drawingGroupTable.jsonBlock');

    const DrawingGroupTableWrapper = ({ drawingGroup, loggedIn }) => {
        return <DrawingGroupTable drawingGroup={drawingGroup} loggedIn={loggedIn} telescript={telescriptDrawingGroupTable} WinnerLink={WinnerLinkWrapper} />;
    };

    return (
        <Layout {...{ page, user, sweep, config, cmsSourceFirebase, actions, path }}>
            <SweepstakeDetails {...{ user, sweep, actions }} telescript={telescriptSweepstakeDetails} DrawingGroupTable={DrawingGroupTableWrapper} />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondChancePromotion);
