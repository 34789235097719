import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { autoTab, CMSContent, IfMobile, ErrorMessage, FindScratchNumbers } from 'sg-ui-components';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { validateScratcherEntry } from '../../validationRules';
import { Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { FaRegTimesCircle } from 'react-icons/fa';
import { FcInfo } from 'react-icons/fc';
import { Modal, Button } from 'react-bootstrap';
import siteConfig from '../../promotionConfig';
import { replaceBadTicketInput } from '../../utils/replaceBadTicketInput';

import IGTOnlyScanner from './IGTOnlyScanner';

/**********************************************************************
 * Component:  ScratchTicketForm
 * Purpose:    Allows for the entry of Scratch Tickets
 *
 * Props:       user - the user data store
 *              actions - store actions (apis)
 *              scratchTicket - the scratch ticket data store
 *
 * APIs used:   scratchTicketActions.enterScratchTicket
 *
 *  Notes:  For MO, does not check Winning Status, only for
 *          bonusing rewards.
 */
const ScratchTicketForm = ({ user, actions, scratchTicket, cmsSourceFirebase }) => {
    const scratchTicketFields = siteConfig.scratchTicketFields;

    const [gameNumberFields, setGameNumberFields] = useState(scratchTicketFields?.gameNumberFields ?? []);
    const [scratchFrontFields, setScratchFrontFields] = useState(scratchTicketFields?.fieldGroups?.[0]?.frontNumberFields ?? []);
    const [scratchBackFields, setScratchBackFields] = useState(scratchTicketFields?.fieldGroups?.[0]?.backNumberFields ?? []);
    const [ticketError, setTicketError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [ticketSuccess, setTicketSuccess] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const elemRefs = [];

    //***********************************************************************************
    // Processes the ticket status and if the ticket was a success, redirect to
    // Post Claim.
    // If Ticket fails, report the error.  If failure was due to Player Lockout, prevent
    // further submitting of tickets.
    //
    useEffect(() => {
        if (disabled) {
            if (scratchTicket.success) {
                updateDashboard();
                setTicketSuccess(true);
            } else {
                setTicketError(scratchTicket.message);
            }
            setDisabled(false);
        }
        if (scratchTicket.message === '_PLAYER_LOCKOUT') {
            setDisabled(true);
        }
    }, [scratchTicket]);

    // Cleanup
    useEffect(() => {
        return () => {
            // clean up and make sure fields are clear
            gameNumberFields.forEach((field) => {
                field.value = '';
            });
            scratchFrontFields.forEach((field) => {
                field.value = '';
            });
            scratchBackFields.forEach((field) => {
                field.value = '';
            });
        };
    }, []);
    //***********************************************************************************
    // Change the Scratch ticket format based on the game number field.
    //
    useEffect(() => {
        const gameNum = gameNumberFields[0].value;
        scratchTicketFields.fieldGroups.forEach((fieldGroup) => {
            if (inRange(gameNum, fieldGroup)) {
                setScratchFrontFields(fieldGroup.frontNumberFields ?? []);
                setScratchBackFields(fieldGroup.backNumberFields ?? []);
                return;
            }
        });

        // force focus onto the next tab.
        elemRefs[1].current.focus();
    }, [gameNumberFields]);

    //***********************************************************************************
    // Updates the dashboard on a ticket entry, see if points have been updated
    //
    const updateDashboard = async () => {
        if (user?.loggedIn && user?.player?.actions && user?.player?.actions.length === 0) {
            await actions.userActions.getDashboard();
        }
    };

    //***********************************************************************************
    // Cycle through the draw fields and set up the input boxes.
    // Autotabbing should be allowed to go from input to input.
    const ScratchFieldInputs = (fields) => {
        return fields.fields.map((field, index) => {
            // to keep front numbers from sharing same index as back numbers
            const ref = React.createRef();
            elemRefs.push(ref);
            return (
                <div className='d-inline-block ticket-number-inputs' key={`scratch-fields--${index}`}>
                    <input
                        type='text'
                        id={field.name}
                        data-index={field.dataindex}
                        ref={ref}
                        className='theme-input'
                        placeholder={field.placeholder}
                        size={field.size}
                        maxLength={field.size}
                        defaultValue={field.value}
                        onChange={(event) => handleFieldChange(event, field)}
                        onKeyUp={(event) => autoTab(event, elemRefs)}
                        required
                    />
                    <span className='dash'>&mdash;</span>
                </div>
            );
        });
    };

    //***********************************************************************************
    // Cycle through the Game ID fields and set up the input boxes.
    // Autotabbing should be allowed to go from input to input.
    const GameIdFieldInputs = () => {
        return gameNumberFields.map((field, index) => {
            const ref = React.createRef();
            elemRefs.push(ref);
            return (
                <div className='d-inline-block ticket-number-inputs' key={`game-numbers--${index}`}>
                    <input
                        type='text'
                        id={field.name}
                        data-index={field.dataindex}
                        ref={ref}
                        className='theme-input'
                        placeholder={field.placeholder}
                        size={field.size}
                        maxLength={field.size}
                        defaultValue={field.value}
                        onBlur={(event) => handleGameNumberChange(event, field)}
                        onKeyUp={(event) => autoTab(event, elemRefs)}
                        required
                    />
                    <span className='dash'>&mdash;</span>
                </div>
            );
        });
    };

    //***********************************************************************************
    // handle a change in the game number and update the game fields accordingly.
    //
    const handleGameNumberChange = async (event, field) => {
        // get gameNum from the event target
        const gameNum = replaceBadTicketInput(event.target);

        // do a deep copy of the gameNumberField state.
        let gameIdField = JSON.parse(JSON.stringify(gameNumberFields));
        // check that we are changing the right index in the array
        const fieldIndex = gameIdField.findIndex((obj) => obj.name == field.name);
        gameIdField[fieldIndex].value = gameNum.value;
        setGameNumberFields(gameIdField);
    };

    // Find if the given number is in this range grouping
    const inRange = (number, rangeGroup) => {
        let numberInRange = false;
        rangeGroup.validRanges.forEach((range) => {
            if (parseInt(number) >= parseInt(range[0]) && parseInt(number) <= parseInt(range[1])) {
                numberInRange = true;
                return;
            }
        });
        return numberInRange;
    };

    //***********************************************************************************
    // onChange callback for the input fields.  Sets the state of the front and back
    // fields
    //
    const handleFieldChange = async (event, field) => {
        const userInput = replaceBadTicketInput(event.target);
        if (field.side === 'front') {
            const frontFields = scratchFrontFields;
            const fieldIndex = frontFields.findIndex((obj) => obj.name == field.name);
            frontFields[fieldIndex].value = userInput.value;
            setScratchFrontFields(frontFields);
        } else {
            const backFields = scratchBackFields;
            const fieldIndex = backFields.findIndex((obj) => obj.name == field.name);
            backFields[fieldIndex].value = userInput.value;
            setScratchBackFields(backFields);
        }
    };

    //***********************************************************************************
    // Handle the submit of the scratch ticket.  Combine all the tickets fields into
    // one string object that is passed to the ScratchTicket API and disable submit
    // until we get a response.
    //
    const handleScratchSubmit = async () => {
        setDisabled(true);
        setValidationError('');
        setTicketSuccess(false);
        let ticketString = '';

        //* Clear scanner errors
        await actions.igt24TicketActions.reset();

        gameNumberFields.forEach((field) => {
            ticketString = ticketString + field.value;
        });

        scratchBackFields.forEach((field) => {
            ticketString = ticketString + field.value;
        });

        scratchFrontFields.forEach((field) => {
            ticketString = ticketString + field.value;
        });

        const scratchEntrySchema = validateScratcherEntry;
        const valid = await scratchEntrySchema.isValid(ticketString);

        if (valid) {
            setTicketError('');

            await actions.scratchTicketActions.enterScratchTicket({
                ticket_number: ticketString,
                entry_method: 'manual',
                ticket_type: 'instant',
            });
        } else {
            await scratchEntrySchema.validate(ticketString, { abortEarly: true }).catch(function (err) {
                setValidationError(err.message);
            });
        }
        setDisabled(false);
    };

    const showScratcherTooltip = () => {
        setShowModel(true);
    };

    const handleModalClose = () => {
        setShowModel(false);
    };

    const resetScratchTicketFormStates = () => {
        setTicketError('');
        setValidationError('');
    };

    return (
        <div>
            {ticketSuccess ? <Redirect to='/post-claim' /> : null}

            {validationError ? (
                <Alert variant='danger'>
                    <FaRegTimesCircle />
                    <div className='alert-text'>{validationError}</div>
                </Alert>
            ) : null}

            <ErrorMessage code={ticketError} collection='data.messages.ticketErrorMessages.jsonBlock' />

            <IfMobile>
                <IGTOnlyScanner resetParentState={resetScratchTicketFormStates} />
            </IfMobile>

            <div className='d-sm-flex justify-content-sm-around align-items-sm-end no-gutters'>
                <div className='col-12 col-sm-7'>
                    <div className='enter-form back-numbers'>
                        <label>Ticket Number</label>
                        <div className='back-numbers-inner-wrapper'>
                            <GameIdFieldInputs />
                            <ScratchFieldInputs fields={scratchBackFields} />
                            <span>
                                <FcInfo className='ticket-tooltip' onClick={() => showScratcherTooltip()} />
                            </span>
                        </div>
                    </div>
                    <div className='enter-form front-numbers mt-3'>
                        <label>Validation Number</label>
                        <ScratchFieldInputs fields={scratchFrontFields} />
                        <FcInfo className='ticket-tooltip' onClick={() => showScratcherTooltip()} />
                    </div>
                </div>

                <div className='col-12 col-sm-5 text-center mt-3 mt-md-0'>
                    <button type='submit' className='btn theme-btn theme-secondary' disabled={disabled} onClick={() => handleScratchSubmit()}>
                        Submit
                    </button>
                    <HashLink to='/my-activity#ticket-history' className='view-history-link d-block mt-1'>
                        View your ticket history
                    </HashLink>
                </div>
            </div>

            <Modal show={showModel} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.ticketEntry.findScratchNumbers.contentHeaderText'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FindScratchNumbers.ScratchNumberExample cmsSourceFirebase={cmsSourceFirebase} />
                </Modal.Body>

                <Modal.Footer>
                    <Button className='btn theme-btn theme-secondary' onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*
            //? For testing:
            <section className='testing-section'>
                <button onClick={() => setTicketError('Scratch: ticket error')}>
                    TEST: Scratch: ticket error
                </button>

                <button onClick={() => setValidationError('Scratch: validation error')}>
                    TEST: Scratch: validation error
                </button>

                <button onClick={() => actions.igt24TicketActions.reset()}>
                    TEST: resetIGTScanner
                </button>
            </section>
            */}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ScratchTicketForm);
