import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { configSlice, configActions } from 'sg-ui-components';
import { pageDataSlice, pageDataActions } from 'sg-ui-components';
import { ptaUserSlice as userSlice, ptaUserActions as userActions } from 'sg-ui-components';
import { checkTicketActions, checkTicketSlice } from 'sg-ui-components';
import { igt24TicketActions, igt24TicketSlice } from 'sg-ui-components';
import { drawTicketActions, drawTicketSlice } from 'sg-ui-components';
import { scratchTicketActions, scratchTicketSlice } from 'sg-ui-components';
import { fastPlayTicketActions, fastPlayTicketSlice } from 'sg-ui-components';
import { entrySlice, entryActions } from 'sg-ui-components';
import { loadingSlice, loadingActions } from 'sg-ui-components';
import { winnerSlice, winnerActions } from 'sg-ui-components';
import { feedbackSlice, feedbackActions } from 'sg-ui-components';
import { feedbackCategoriesSlice, feedbackCategoriesActions } from 'sg-ui-components';
import { revealTokenSlice, revealTokenActions } from 'sg-ui-components';
import { notificationSlice, notificationActions } from 'sg-ui-components';
import { achievementSlice, achievementActions } from 'sg-ui-components';
import { pointSlice, pointActions } from 'sg-ui-components';
import { activitySlice, activityActions } from 'sg-ui-components';
import { rewardSlice, rewardActions } from 'sg-ui-components';
import { couponSlice, couponActions } from 'sg-ui-components';
import { promotionSlice, promotionActions } from 'sg-ui-components';
import { sweepSlice, sweepActions } from 'sg-ui-components';
import { cmsSourceFirebaseActions, cmsSourceFirebaseSlice } from 'sg-ui-components';
import { optinsSlice, optinActions } from 'sg-ui-components';
import { bonusingSlice, bonusingActions } from 'sg-ui-components';
import { surveySlice, surveyActions } from 'sg-ui-components';
import { thirdPartyPrizeSlice, thirdPartyPrizeActions } from 'sg-ui-components';
import { bannerSlice, bannerActions } from 'sg-ui-components';

import apiClient from './apiClient';
import promotionConfig from './promotionConfig';
import validateTicketResponse from './validateTicketResponse';

export const createStore = () => {
    return configureStore({
        reducer: combineReducers({
            config: configSlice.reducer,
            user: userSlice.reducer,
            pageData: pageDataSlice.reducer,
            checkTicket: checkTicketSlice.reducer,
            igt24Ticket: igt24TicketSlice.reducer,
            drawTicket: drawTicketSlice.reducer,
            scratchTicket: scratchTicketSlice.reducer,
            fastPlayTicket: fastPlayTicketSlice.reducer,
            entry: entrySlice.reducer,
            loading: loadingSlice.reducer,
            winner: winnerSlice.reducer,
            feedback: feedbackSlice.reducer,
            feedbackCategories: feedbackCategoriesSlice.reducer,
            revealToken: revealTokenSlice.reducer,
            notification: notificationSlice.reducer,
            achievement: achievementSlice.reducer,
            points: pointSlice.reducer,
            activity: activitySlice.reducer,
            reward: rewardSlice.reducer,
            coupon: couponSlice.reducer,
            promotion: promotionSlice.reducer,
            sweep: sweepSlice.reducer,
            cmsSourceFirebase: cmsSourceFirebaseSlice.reducer,
            optins: optinsSlice.reducer,
            bonusing: bonusingSlice.reducer,
            survey: surveySlice.reducer,
            thirdPartyPrizes: thirdPartyPrizeSlice.reducer,
            banners: bannerSlice.reducer,
        }),
    });
};

export const actionsStore = (dispatch) => {
    return {
        configActions: configActions(dispatch, apiClient),
        userActions: userActions(dispatch, apiClient),
        pageDataActions: pageDataActions(dispatch, apiClient, promotionConfig),
        checkTicketActions: checkTicketActions(dispatch, apiClient),
        igt24TicketActions: igt24TicketActions(dispatch, apiClient, validateTicketResponse),
        drawTicketActions: drawTicketActions(dispatch, apiClient, validateTicketResponse),
        scratchTicketActions: scratchTicketActions(dispatch, apiClient, validateTicketResponse),
        fastPlayTicketActions: fastPlayTicketActions(dispatch, apiClient),
        entryActions: entryActions(dispatch, apiClient, promotionConfig),
        loadingActions: loadingActions(dispatch, apiClient),
        winnerActions: winnerActions(dispatch, apiClient),
        feedbackActions: feedbackActions(dispatch, apiClient),
        feedbackCategoriesActions: feedbackCategoriesActions(dispatch, apiClient),
        revealTokenActions: revealTokenActions(dispatch, apiClient),
        notificationActions: notificationActions(dispatch, apiClient),
        achievementActions: achievementActions(dispatch, apiClient),
        pointActions: pointActions(dispatch, apiClient),
        activityActions: activityActions(dispatch, apiClient),
        rewardActions: rewardActions(dispatch, apiClient),
        couponActions: couponActions(dispatch, apiClient),
        promotionActions: promotionActions(dispatch, apiClient),
        sweepActions: sweepActions(dispatch, apiClient),
        cmsSourceFirebaseActions: cmsSourceFirebaseActions(dispatch, apiClient),
        optinsActions: optinActions(dispatch, apiClient),
        bonusingActions: bonusingActions(dispatch, apiClient),
        surveyActions: surveyActions(dispatch, apiClient),
        thirdPartyPrizeActions: thirdPartyPrizeActions(dispatch, apiClient),
        bannerActions: bannerActions(dispatch, apiClient),
    };
};

export const mapStateToProps = ({
    config,
    user,
    pageData,
    checkTicket,
    igt24Ticket,
    drawTicket,
    scratchTicket,
    fastPlayTicket,
    entry,
    loading,
    winner,
    feedback,
    feedbackCategories,
    revealToken,
    notification,
    achievement,
    points,
    activity,
    reward,
    coupon,
    promotion,
    sweep,
    cmsSourceFirebase,
    optins,
    bonusing,
    survey,
    thirdPartyPrizes,
    banners,
}) => ({
    config,
    user,
    pageData,
    checkTicket,
    igt24Ticket,
    drawTicket,
    scratchTicket,
    fastPlayTicket,
    entry,
    loading,
    winner,
    feedback,
    feedbackCategories,
    revealToken,
    notification,
    achievement,
    points,
    activity,
    reward,
    coupon,
    promotion,
    sweep,
    cmsSourceFirebase,
    optins,
    bonusing,
    survey,
    thirdPartyPrizes,
    banners,
});
export const mapDispatchToProps = (dispatch) => {
    return {
        actions: actionsStore(dispatch),
    };
};
