import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';

const WrapperFooter = ({ config }) => {
    const lotteryUrl = config?.config?.lottery_host ?? 'https://www.galottery.com';

    return (
        <div className='lottery-wrapper'>
            <div className='footer-rtl'>
                <div className='container-fluid'>
                    <div className='rtl-search rtl-search-footer text-center'>
                        <a className='btn btn-success btn-wide retail-search-button' href={`${lotteryUrl}/en-us/player-zone/where-to-play.html`}>
                            Where to Play
                        </a>
                    </div>
                </div>
            </div>

            <footer>
                <div className='footer-nav'>
                    <div className='container-fluid'>
                        <div className='footer-nav__ihope'>
                            <a href={`${lotteryUrl}/en-us/ihope.html`}>
                                {' '}
                                <img
                                    src='/wrapper/content/dam/portal/images/footer/ihope.png'
                                    alt='iHope'
                                    className='img-responsive footer-nav__ihope-img'
                                />{' '}
                            </a>
                        </div>
                        <div className='footerlinks footer-nav-pills'>
                            <div id='footer-nav-pills'>
                                <ul className='nav nav-pills'>
                                    <li>
                                        <a title='Play Responsibly' href={`${lotteryUrl}/en-us/about-us/play-responsibly.html`}>
                                            Play Responsibly
                                        </a>
                                    </li>
                                    <li>
                                        <a title='FAQs' href={`${lotteryUrl}/en-us/player-zone/player-faqs.html`}>
                                            FAQs
                                        </a>
                                    </li>
                                    <li>
                                        <a title='Contact Us' href={`${lotteryUrl}/en-us/contact-us.html`}>
                                            Contact Us
                                        </a>
                                    </li>
                                    <li>
                                        <a title='Careers' href={`${lotteryUrl}/en-us/about-us/careers.html`}>
                                            Careers
                                        </a>
                                    </li>
                                    <li>
                                        <a title='Help' href={`${lotteryUrl}/en-us/help.html`}>
                                            Help
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='footer-nav-links'>
                            <div className='portalrichtextconfig portalrichtextconfig_1'>
                                <div id='footer_mobileapp' className=''>
                                    <div className='footer-app'>
                                        <i className='sprite-mobilephones mphones'></i>
                                        <h3>Download GA Mobile App</h3>
                                        <a
                                            id='aDownloadFromItunes'
                                            rel='noreferrer'
                                            title='Download the app from the app store'
                                            href='https://itunes.apple.com/us/app/georgia-lottery-official-app/id1041429575?ls=1&amp;mt=8'
                                            target='_blank'>
                                            <i className='sprite-appstore'></i>
                                        </a>{' '}
                                        <a
                                            id='aDownloadFromPlayStore'
                                            rel='noreferrer'
                                            title='Download the app from the app store'
                                            href={`${lotteryUrl}/en-us/androidinfo.html`}
                                            target='_blank'>
                                            <i className='sprite-android'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='footer-footnote'>
                        <div className='footer-copyright footerlinks'>
                            <div id='footer-copyright'>
                                <ul className='copyright-links'>
                                    <li>
                                        <a title='Georgia Lottery Privacy Policy' href={`${lotteryUrl}/en-us/content/privacy-policy.html`}>
                                            Georgia Lottery Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a title='Georgia Lottery Terms of Use' href={`${lotteryUrl}/en-us/content/termsandconditions.html`}>
                                            Georgia Lottery Terms of Use
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            title='SMS Terms and Conditions'
                                            href={`${lotteryUrl}/en-us/sms-terms-conditions.html`}>
                                            SMS Terms and Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a target='_blank' rel='noreferrer' title='State of Georgia' href='http://www.georgia.gov/'>
                                            State of Georgia
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='portalrichtextconfig portalrichtextconfig_0'>
                            <div id='patriot' className=''>
                                <div className='patriot'>Player Information Hotline: 1-800-GA-LUCKY</div>
                                <div className='copyright'>
                                    <p>
                                        <b>Copyright {moment().format('YYYY')}, Georgia Lottery Corporation. All rights reserved.</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='center image-links'>
                    <div className='portalrichtextconfig'>
                        <div id='footer_image_logos' className=''>
                            <p>
                                <a rel='noreferrer' href='https://www.world-lotteries.org/' target='_blank' title='World Lotteries'>
                                    <img src='/wrapper/content/dam/portal/images/footer/wla.png' />
                                </a>
                                <a rel='noreferrer' href='http://www.ncpgambling.org/' target='_blank' title='nspg'>
                                    <img src='/wrapper/content/dam/portal/images/footer/nspg.png' />
                                </a>
                                <a rel='noreferrer' href='http://www.ncpgambling.org/programs-resources/responsible-gaming/icap/' target='_blank' title='iCAP'>
                                    <img src='/wrapper/content/dam/portal/images/footer/icap.png' />
                                </a>
                                <img src='/wrapper/content/dam/portal/images/footer/GodaddyVerifiedSecuredSeal.png' />
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WrapperFooter);
