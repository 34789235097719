import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import stripsTagsAndSlashes from '../../utils/stripsTagsAndSlashes';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import IfNotMobile from '../IfNotMobile/IfNotMobile';
var TitleBreadcrumb = function (_a) {
    var path = _a.path, routes = _a.routes;
    var data = routes && routes[stripsTagsAndSlashes(path)] ? routes[stripsTagsAndSlashes(path)] : null;
    if (!data) {
        return null;
    }
    return (_jsx("div", { className: 'row justify-content-center', children: _jsxs("div", { className: 'col', children: [_jsx(IfNotMobile, { children: _jsx(Breadcrumbs, { id: data === null || data === void 0 ? void 0 : data.title, data: data === null || data === void 0 ? void 0 : data.breadcrumb }) }), data.pageHeader && _jsx("h1", { className: 'section-title', children: data.pageHeader })] }) }));
};
export default TitleBreadcrumb;
