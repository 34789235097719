import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { getCMSObject, isApp } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import { NavLink, useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import HomeHeroBanner from '../../HomeHeroBanner';
import { UserMenu } from './UserMenu';
import { isArray } from 'lodash';

const Header = () => {
    return (
        <div className='page-header'>
            <header>
                <div className={isApp() ? 'mb-4' : 'mb-dynamic'}>
                    <div className='clearfix' />
                    <HomeHeroBanner />
                    <PrimaryNav />
                    <MobilePrimaryNav />
                </div>
            </header>
        </div>
    );
};

const PrimaryNavTemplate = () => {
    const primaryNavFromCMS = getCMSObject('data.sitewideSettings.primaryNav.jsonBlock') ?? [];
    const mainMenu = isArray(primaryNavFromCMS) ? primaryNavFromCMS : [];
    const location = useLocation();

    return (
        <Navbar variant className='desktop-nav-row'>
            <Nav className='mr-auto' as='ul'>
                {mainMenu.map((link, key) => {
                    if (link.subMenu === undefined) {
                        return (
                            <li key={`main-menu--${key}`}>
                                <NavLink to={link.uri} activeclassname='active' className='nav-link' role='button'>
                                    {link.title}
                                </NavLink>
                            </li>
                        );
                    } else if (link.subMenu.length) {
                        return (
                            <li className='has-dropdown' key={`main-menu--${key}`}>
                                <NavDropdown
                                    active={location.pathname === '/feedback'}
                                    activeclassname='active'
                                    alignRight
                                    key={`dropdown-${key}`}
                                    title={link.title}
                                    className='nav-submenu'>
                                    {link.subMenu.map((subLink, key) => {
                                        return (
                                            <NavDropdown.Item
                                                key={`sub-menu--${key}`}
                                                as={NavHashLink}
                                                to={subLink.uri + '#' + subLink.anchor}
                                                activeclassname='active'>
                                                {subLink.title}
                                            </NavDropdown.Item>
                                        );
                                    })}
                                </NavDropdown>
                            </li>
                        );
                    }
                })}
            </Nav>
            <UserMenu />
        </Navbar>
    );
};

const PrimaryNav = connect(mapStateToProps, mapDispatchToProps)(PrimaryNavTemplate);

const MobilePrimaryNavTemplate = () => {
    const [toggleText, setToggleText] = useState('MENU');

    const primaryNavFromCMS = getCMSObject('data.sitewideSettings.primaryNav.jsonBlock') ?? [];
    const mobileMainMenu = isArray(primaryNavFromCMS) ? primaryNavFromCMS : [];

    return (
        <div className='mobile-nav-row'>
            <Dropdown drop='down' id='mobile-nav-dropdown' onSelect={(ek, ev) => setToggleText(ev.target.innerText)} className='flex-grow-1'>
                <Dropdown.Toggle id='mobile-nav-toggle' className='w-100' variant>
                    {toggleText}
                </Dropdown.Toggle>
                <Dropdown.Menu className='mobile-dropdown-menu'>
                    {mobileMainMenu.map((link, key) => {
                        return (
                            <Dropdown.Item key={`mobile-menu--${key}`} as={NavLink} to={link.uri} activeclassname='active' className='nav-link'>
                                {link.title}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
            <UserMenu />
        </div>
    );
};

const MobilePrimaryNav = connect(mapStateToProps, mapDispatchToProps)(MobilePrimaryNavTemplate);

export { Header, PrimaryNav, MobilePrimaryNav };
